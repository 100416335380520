import React from "react";

function HeroSVG() {
  return (
    <svg width="874" height="497" viewBox="0 0 874 497" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M156.924 101.049C127.424 94.7951 111.798 96.7366 86.2533 114.08C32.7325 164.79 58.6229 191.162 86.2533 238.882C86.2533 238.882 74.9229 278.941 74.1288 305.293C73.5433 324.725 78.1385 354.913 78.1385 354.913C62.0331 390.656 67.1249 407.587 80.394 436.862C110.372 474.926 127.09 471.88 156.924 470.693C190.333 497.735 218.532 500.609 277.872 494C292.418 497.273 359.224 471.435 378.46 443.628C434.806 418.111 457.465 394.924 485.374 341.13C508.143 273.248 506.625 238.846 490.231 180.992C507.222 158.551 509.523 145.32 506.675 121.097C502.931 97.5524 498.806 83.2172 481.961 67.4677C465.034 51.6413 448.271 49.0338 426.481 46.4168C410.325 45.0188 396.626 48.9856 366.431 61.9543C345.67 52.3741 334.38 52.1557 314.305 52.6819C315.002 39.399 300.176 17.597 300.176 17.597C300.176 17.597 275.556 -6.21065 246.391 1.5582C217.225 9.32705 203.298 28.4715 202.535 73.2316C188.13 81.1863 179.65 85.9909 156.924 101.049Z" fill="#91115A"/>
    <circle cx="431.797" cy="252.314" r="59.5201" fill="white"/>
    <path d="M466.127 208.776C439.355 200.162 417.403 212.925 406.718 229.706C395.743 246.94 388.046 267.871 410.663 295.876" stroke="#CE1459" strokeWidth="4"/>
    <path d="M444.929 214.535C430.043 212.442 417.427 223.641 411.068 235.975C404.537 248.643 399.774 263.288 411.922 278.555" stroke="#CE1459"/>
    <path d="M390.639 243.334C384.405 251.543 387.27 267.492 392.917 280.168C398.716 293.187 406.341 306.429 419.946 309.13" stroke="#CE1459"/>
    <path d="M315.379 52.5187C309.496 48.2045 302.524 47.5573 297.513 47.9887C292.502 48.4202 284.868 51.2339 277.686 59.6373C270.504 68.0406 176.807 244.504 170.706 260.251C164.606 275.998 167.002 291.098 170.706 297.138C174.41 303.178 239.121 394.425 242.389 397.661C245.657 400.897 253.314 405.036 261.998 404.995C272.297 404.472 277.315 402.553 283.569 393.347C283.569 393.347 390.898 192.949 394.47 183.889C398.042 174.829 398.924 169.922 397.52 161.023C396.586 155.099 395.995 153.689 392.291 146.57C392.291 146.57 321.262 56.833 315.379 52.5187Z" fill="url(#paint0_linear_23_911)"/>
    <path d="M289.497 60.0868C285.616 60.7338 284.869 61.1755 282.597 64.1842L183.181 259.35C178.602 269.369 176.62 274.434 176.927 279.837C177.828 287.349 178.949 291.252 183.181 297.089L250.464 390.035C252.809 392.521 254.579 393.26 258.659 393.27C262.299 393.315 264.288 392.287 267.716 388.095L374.033 172.226C377.362 165.963 377.008 165.58 376.837 164.247C375.743 162.111 374.632 160.85 372.955 158.64L300.28 64.1842C296.622 61.1616 293.379 59.4399 289.497 60.0868Z" fill="white"/>
    <path d="M290.624 80.0282C289.608 79.1597 288.581 78.8393 287.173 78.95C285.517 79.0802 284.312 79.8591 283.507 81.1065L273.587 99.437C272.781 102.164 272.884 103.632 274.234 106.122C274.234 106.122 284.585 120.355 285.232 120.787C285.879 121.218 287.441 121.846 288.898 121.649C290.317 121.457 291.383 121.002 292.133 119.924C292.883 118.846 301.191 102.24 301.191 102.24C302.55 99.8362 302.792 98.3835 302.053 95.5552C301.314 92.7269 290.624 80.0282 290.624 80.0282Z" fill="#230B5C" fillOpacity="0.52"/>
    <path d="M315.639 111.945C314.624 111.076 313.596 110.756 312.189 110.867C310.533 110.997 309.327 111.776 308.523 113.023L298.603 131.354C297.796 134.08 297.899 135.548 299.25 138.039C299.25 138.039 309.601 152.272 310.248 152.703C310.895 153.134 312.456 153.763 313.914 153.566C315.333 153.374 316.399 152.919 317.149 151.841C317.899 150.762 326.206 134.157 326.206 134.157C327.565 131.753 327.808 130.3 327.069 127.472C326.33 124.643 315.639 111.945 315.639 111.945Z" fill="#CE1459"/>
    <path d="M294.505 148.174C293.49 147.306 292.462 146.986 291.055 147.096C289.399 147.226 288.194 148.005 287.389 149.253L277.469 167.583C276.662 170.31 276.766 171.778 278.116 174.268C278.116 174.268 288.467 188.502 289.114 188.933C289.761 189.364 291.323 189.993 292.78 189.795C294.199 189.603 295.265 189.148 296.015 188.07C296.765 186.992 305.072 170.387 305.072 170.387C306.431 167.982 306.674 166.53 305.935 163.701C305.196 160.873 294.505 148.174 294.505 148.174Z" fill="#230B5C" fillOpacity="0.52"/>
    <path d="M270.783 119.277C269.768 118.409 268.74 118.088 267.333 118.199C265.677 118.329 264.472 119.108 263.667 120.355L253.747 138.686C252.941 141.413 253.044 142.881 254.394 145.371C254.394 145.371 264.745 159.604 265.392 160.035C266.039 160.467 267.601 161.095 269.058 160.898C270.477 160.706 271.543 160.251 272.293 159.173C273.043 158.094 281.35 141.489 281.35 141.489C282.71 139.085 282.952 137.632 282.213 134.804C281.474 131.976 270.783 119.277 270.783 119.277Z" fill="#CE1459"/>
    <path d="M319.952 176.641C318.937 175.772 317.909 175.452 316.502 175.562C314.846 175.693 313.641 176.472 312.836 177.719L302.916 196.049C302.109 198.776 302.213 200.244 303.563 202.735C303.563 202.735 313.914 216.968 314.561 217.399C315.208 217.83 316.77 218.459 318.227 218.262C319.646 218.07 320.712 217.615 321.462 216.536C322.212 215.458 330.519 198.853 330.519 198.853C331.878 196.449 332.121 194.996 331.382 192.168C330.643 189.339 319.952 176.641 319.952 176.641Z" fill="#CE1459"/>
    <path d="M342.811 140.411C341.796 139.543 340.768 139.222 339.361 139.333C337.705 139.463 336.5 140.242 335.695 141.489L325.775 159.82C324.969 162.547 325.072 164.015 326.422 166.505C326.422 166.505 336.773 180.738 337.42 181.169C338.067 181.601 339.629 182.229 341.086 182.032C342.505 181.84 343.571 181.385 344.321 180.307C345.071 179.228 353.378 162.623 353.378 162.623C354.738 160.219 354.98 158.766 354.241 155.938C353.502 153.11 342.811 140.411 342.811 140.411Z" fill="#230B5C" fillOpacity="0.52"/>
    <g filter="url(#filter0_d_23_911)">
    <rect x="12.9999" y="207.889" width="247.482" height="106.7" rx="18" fill="white"/>
    </g>
    <path d="M25.7008 242.514H244.354C244.467 242.514 244.576 242.47 244.655 242.391C244.735 242.311 244.78 242.204 244.78 242.092C244.78 241.98 244.735 241.872 244.655 241.793C244.576 241.714 244.467 241.669 244.354 241.669H25.7008C25.5879 241.669 25.4796 241.714 25.3997 241.793C25.3199 241.872 25.2751 241.98 25.2751 242.092C25.2751 242.204 25.3199 242.311 25.3997 242.391C25.4796 242.47 25.5879 242.514 25.7008 242.514Z" fill="#E6E6E6"/>
    <path d="M25.7008 259.411H244.354C244.467 259.411 244.576 259.367 244.655 259.287C244.735 259.208 244.78 259.101 244.78 258.989C244.78 258.877 244.735 258.769 244.655 258.69C244.576 258.611 244.467 258.566 244.354 258.566H25.7008C25.5879 258.566 25.4796 258.611 25.3997 258.69C25.3199 258.769 25.2751 258.877 25.2751 258.989C25.2751 259.101 25.3199 259.208 25.3997 259.287C25.4796 259.367 25.5879 259.411 25.7008 259.411Z" fill="#E6E6E6"/>
    <path d="M25.7008 276.308H244.354C244.467 276.308 244.576 276.263 244.655 276.184C244.735 276.105 244.78 275.997 244.78 275.885C244.78 275.773 244.735 275.666 244.655 275.586C244.576 275.507 244.467 275.463 244.354 275.463H25.7008C25.5879 275.463 25.4796 275.507 25.3997 275.586C25.3199 275.666 25.2751 275.773 25.2751 275.885C25.2751 275.997 25.3199 276.105 25.3997 276.184C25.4796 276.263 25.5879 276.308 25.7008 276.308Z" fill="#E6E6E6"/>
    <path d="M25.7008 293.204H244.354C244.467 293.204 244.576 293.16 244.655 293.08C244.735 293.001 244.78 292.894 244.78 292.782C244.78 292.67 244.735 292.562 244.655 292.483C244.576 292.404 244.467 292.359 244.354 292.359H25.7008C25.5879 292.359 25.4796 292.404 25.3997 292.483C25.3199 292.562 25.2751 292.67 25.2751 292.782C25.2751 292.894 25.3199 293.001 25.3997 293.08C25.4796 293.16 25.5879 293.204 25.7008 293.204Z" fill="#E6E6E6"/>
    <path d="M25.7008 310.101H244.354C244.467 310.101 244.576 310.056 244.655 309.977C244.735 309.898 244.78 309.79 244.78 309.678C244.78 309.566 244.735 309.459 244.655 309.38C244.576 309.3 244.467 309.256 244.354 309.256H25.7008C25.5879 309.256 25.4796 309.3 25.3997 309.38C25.3199 309.459 25.2751 309.566 25.2751 309.678C25.2751 309.79 25.3199 309.898 25.3997 309.977C25.4796 310.056 25.5879 310.101 25.7008 310.101Z" fill="#E6E6E6"/>
    <path d="M192.577 310.758C192.689 310.758 192.797 310.714 192.877 310.635C192.957 310.556 193.002 310.45 193.003 310.338L193.428 241.907C193.429 241.795 193.385 241.687 193.306 241.607C193.226 241.528 193.118 241.482 193.005 241.482H193.003C192.89 241.482 192.782 241.526 192.703 241.605C192.623 241.683 192.578 241.79 192.577 241.902L192.151 310.333C192.151 310.445 192.195 310.553 192.274 310.632C192.353 310.712 192.461 310.757 192.574 310.758H192.577Z" fill="#E6E6E6"/>
    <path d="M133.405 310.758C133.518 310.758 133.625 310.714 133.705 310.635C133.785 310.556 133.83 310.45 133.831 310.338L134.256 241.907C134.257 241.795 134.213 241.687 134.134 241.607C134.054 241.528 133.946 241.482 133.833 241.482H133.831C133.718 241.482 133.61 241.526 133.531 241.605C133.451 241.683 133.406 241.79 133.405 241.902L132.979 310.333C132.979 310.445 133.023 310.553 133.102 310.632C133.182 310.712 133.29 310.757 133.402 310.758H133.405Z" fill="#E6E6E6"/>
    <path d="M74.2338 310.758C74.3463 310.758 74.4542 310.714 74.5339 310.635C74.6137 310.556 74.6588 310.45 74.6595 310.338L75.0852 241.907C75.0859 241.795 75.0417 241.687 74.9624 241.607C74.8831 241.528 74.7751 241.482 74.6622 241.482H74.6595C74.5471 241.482 74.4392 241.526 74.3595 241.605C74.2797 241.683 74.2345 241.79 74.2338 241.902L73.8081 310.333C73.8075 310.445 73.8516 310.553 73.931 310.632C74.0103 310.712 74.1182 310.757 74.2311 310.758H74.2338Z" fill="#E6E6E6"/>
    <path d="M199.601 228.176H240.042C241.566 228.176 243.028 227.575 244.106 226.505C245.183 225.436 245.789 223.986 245.789 222.473C245.789 220.961 245.183 219.51 244.106 218.441C243.028 217.371 241.566 216.771 240.042 216.771H199.601C198.077 216.771 196.615 217.371 195.537 218.441C194.459 219.51 193.854 220.961 193.854 222.473C193.854 223.986 194.459 225.436 195.537 226.505C196.615 227.575 198.077 228.176 199.601 228.176V228.176Z" fill="#E6E6E6"/>
    <path d="M211.71 255.421H227.933C228.545 255.422 229.151 255.303 229.717 255.071C230.283 254.839 230.797 254.498 231.23 254.069C231.664 253.639 232.007 253.129 232.242 252.568C232.476 252.007 232.597 251.405 232.597 250.798C232.597 250.19 232.476 249.589 232.242 249.027C232.007 248.466 231.664 247.956 231.23 247.527C230.797 247.097 230.283 246.757 229.717 246.525C229.151 246.293 228.545 246.173 227.933 246.174H211.71C211.098 246.173 210.491 246.293 209.926 246.525C209.36 246.757 208.846 247.097 208.412 247.527C207.979 247.956 207.636 248.466 207.401 249.027C207.167 249.589 207.046 250.19 207.046 250.798C207.046 251.405 207.167 252.007 207.401 252.568C207.636 253.129 207.979 253.639 208.412 254.069C208.846 254.498 209.36 254.839 209.926 255.071C210.491 255.303 211.098 255.422 211.71 255.421Z" fill="#CE1459"/>
    <path d="M155.092 271.896H171.315C172.551 271.896 173.736 271.409 174.61 270.541C175.484 269.674 175.975 268.498 175.975 267.272C175.975 266.046 175.484 264.87 174.61 264.003C173.736 263.135 172.551 262.648 171.315 262.648H155.092C154.48 262.648 153.874 262.767 153.308 262.999C152.742 263.231 152.228 263.572 151.795 264.001C151.361 264.43 151.018 264.94 150.783 265.502C150.549 266.063 150.428 266.664 150.428 267.272C150.428 267.88 150.549 268.481 150.783 269.042C151.018 269.604 151.361 270.114 151.795 270.543C152.228 270.972 152.742 271.313 153.308 271.545C153.874 271.777 154.48 271.896 155.092 271.896Z" fill="#CE1459"/>
    <path d="M155.093 290.06H171.315C171.928 290.06 172.534 289.941 173.1 289.709C173.666 289.477 174.18 289.136 174.613 288.707C175.046 288.277 175.39 287.768 175.624 287.206C175.859 286.645 175.979 286.043 175.979 285.436C175.979 284.828 175.859 284.227 175.624 283.665C175.39 283.104 175.046 282.594 174.613 282.165C174.18 281.735 173.666 281.395 173.1 281.163C172.534 280.931 171.928 280.812 171.315 280.812H155.093C154.48 280.812 153.874 280.931 153.308 281.163C152.742 281.395 152.228 281.735 151.795 282.165C151.362 282.594 151.018 283.104 150.784 283.665C150.549 284.227 150.429 284.828 150.429 285.436C150.429 286.043 150.549 286.645 150.784 287.206C151.018 287.768 151.362 288.277 151.795 288.707C152.228 289.136 152.742 289.477 153.308 289.709C153.874 289.941 154.48 290.06 155.093 290.06Z" fill="#CE1459"/>
    <path d="M94.6431 255.844H110.866C112.101 255.844 113.287 255.357 114.16 254.49C115.034 253.623 115.525 252.446 115.525 251.22C115.525 249.994 115.034 248.818 114.16 247.951C113.287 247.084 112.101 246.596 110.866 246.596H94.6431C94.0308 246.596 93.4244 246.715 92.8586 246.947C92.2927 247.179 91.7785 247.52 91.3454 247.949C90.9122 248.379 90.5686 248.888 90.3341 249.45C90.0997 250.011 89.979 250.613 89.979 251.22C89.979 251.828 90.0997 252.429 90.3341 252.991C90.5686 253.552 90.9122 254.062 91.3454 254.491C91.7785 254.921 92.2927 255.261 92.8586 255.493C93.4244 255.725 94.0308 255.845 94.6431 255.844Z" fill="#CE1459"/>
    <path d="M142.203 228.387H182.644C184.168 228.387 185.63 227.786 186.708 226.717C187.786 225.647 188.391 224.197 188.391 222.684C188.391 221.172 187.786 219.721 186.708 218.652C185.63 217.583 184.168 216.982 182.644 216.982H142.203C140.679 216.982 139.217 217.583 138.139 218.652C137.062 219.721 136.456 221.172 136.456 222.684C136.456 224.197 137.062 225.647 138.139 226.717C139.217 227.786 140.679 228.387 142.203 228.387V228.387Z" fill="#E6E6E6"/>
    <path d="M84.8046 228.809H125.246C126.77 228.809 128.232 228.209 129.309 227.139C130.387 226.07 130.993 224.619 130.993 223.107C130.993 221.594 130.387 220.144 129.309 219.074C128.232 218.005 126.77 217.404 125.246 217.404H84.8046C83.2804 217.404 81.8187 218.005 80.741 219.074C79.6632 220.144 79.0577 221.594 79.0577 223.107C79.0577 224.619 79.6632 226.07 80.741 227.139C81.8187 228.209 83.2804 228.809 84.8046 228.809V228.809Z" fill="#E6E6E6"/>
    <path d="M27.4072 228.809H67.8483C69.3725 228.809 70.8342 228.209 71.912 227.139C72.9897 226.07 73.5952 224.619 73.5952 223.107C73.5952 221.594 72.9897 220.144 71.912 219.074C70.8342 218.005 69.3725 217.404 67.8483 217.404H27.4072C25.883 217.404 24.4213 218.005 23.3435 219.074C22.2658 220.144 21.6603 221.594 21.6603 223.107C21.6603 224.619 22.2658 226.07 23.3435 227.139C24.4213 228.209 25.883 228.809 27.4072 228.809Z" fill="#E6E6E6"/>
    <path d="M222.387 226.013L218.511 219.746L219.679 219.595L217.32 225.483C217.271 225.642 217.162 225.777 217.016 225.859C216.87 225.941 216.698 225.965 216.535 225.926C216.372 225.88 216.234 225.773 216.151 225.627C216.067 225.481 216.045 225.308 216.089 225.146L218.447 219.258C218.484 219.141 218.554 219.036 218.649 218.957C218.743 218.878 218.859 218.828 218.982 218.812C219.105 218.796 219.229 218.815 219.341 218.867C219.454 218.92 219.548 219.003 219.614 219.107L223.489 225.374C223.92 226.071 222.816 226.708 222.387 226.013H222.387Z" fill="white"/>
    <path d="M221.511 223.904L218.132 223.854C217.963 223.854 217.8 223.787 217.681 223.668C217.561 223.549 217.494 223.388 217.494 223.22C217.494 223.052 217.561 222.891 217.681 222.772C217.8 222.653 217.963 222.587 218.132 222.587L221.511 222.637C221.68 222.637 221.843 222.704 221.962 222.822C222.082 222.941 222.149 223.102 222.149 223.27C222.149 223.438 222.082 223.6 221.962 223.718C221.843 223.837 221.68 223.904 221.511 223.904V223.904Z" fill="white"/>
    <path d="M160.21 222.538C160.297 222.365 160.411 222.207 160.549 222.07C160.333 221.802 160.207 221.473 160.191 221.131C160.174 220.788 160.267 220.449 160.456 220.162C160.772 219.694 161.251 219.359 161.801 219.221C162.449 219.095 163.117 219.108 163.759 219.262C163.802 219.252 163.846 219.247 163.89 219.246C164.058 219.249 164.219 219.317 164.338 219.435C164.457 219.553 164.526 219.713 164.528 219.88C164.603 221.545 164.679 223.21 164.754 224.875C164.78 224.913 164.801 224.955 164.817 224.998C164.851 225.144 164.836 225.297 164.773 225.434C164.76 225.592 164.687 225.74 164.57 225.849C164.452 225.957 164.299 226.019 164.138 226.021C163.979 226.022 163.825 225.96 163.711 225.85C162.45 225.944 161.128 225.74 160.374 224.605C160.17 224.303 160.048 223.955 160.019 223.593C159.991 223.231 160.056 222.868 160.21 222.538V222.538ZM161.591 224.102C161.847 224.319 162.147 224.477 162.47 224.566C162.794 224.655 163.134 224.673 163.465 224.617C163.402 223.232 163.34 221.846 163.277 220.46C162.703 220.366 162.049 220.316 161.652 220.7C161.587 220.762 161.533 220.834 161.492 220.914C161.487 220.926 161.456 221.084 161.46 221.04C161.453 221.132 161.45 221.085 161.475 221.17C161.502 221.256 161.551 221.333 161.618 221.394C161.685 221.455 161.767 221.496 161.856 221.515C162.024 221.519 162.184 221.587 162.303 221.705C162.422 221.823 162.491 221.982 162.495 222.149C162.493 222.316 162.425 222.476 162.305 222.594C162.186 222.713 162.025 222.78 161.856 222.782C161.725 222.786 161.598 222.833 161.497 222.917C161.396 223.001 161.327 223.115 161.3 223.243C161.276 223.399 161.29 223.558 161.341 223.708C161.392 223.857 161.477 223.992 161.591 224.102H161.591Z" fill="white"/>
    <path d="M103.885 219.585C104.81 219.756 105.649 220.236 106.262 220.946C106.874 221.655 107.222 222.552 107.249 223.486C107.289 224.477 106.936 225.445 106.265 226.18C105.504 226.955 104.448 226.963 103.439 226.829C103.271 226.824 103.112 226.755 102.994 226.638C102.875 226.52 102.806 226.362 102.801 226.195C102.801 225.888 103.095 225.516 103.439 225.562C104.097 225.649 104.894 225.791 105.395 225.251C105.823 224.727 106.029 224.058 105.968 223.386C105.919 222.747 105.652 222.144 105.212 221.675C104.772 221.207 104.184 220.901 103.545 220.807C103.383 220.761 103.245 220.654 103.161 220.508C103.078 220.362 103.056 220.19 103.099 220.028C103.147 219.868 103.256 219.732 103.402 219.65C103.549 219.568 103.722 219.544 103.885 219.585V219.585Z" fill="white"/>
    <path d="M49.6173 220.184L49.4536 226.293C49.4518 226.39 49.4279 226.485 49.3837 226.571C49.3396 226.658 49.2762 226.733 49.1985 226.792C49.1207 226.851 49.0306 226.892 48.9348 226.911C48.839 226.93 48.74 226.928 48.6453 226.904C47.7925 226.727 47.0272 226.264 46.479 225.592C45.9308 224.92 45.6334 224.081 45.6371 223.217C45.6409 222.352 45.9454 221.516 46.4994 220.849C47.0533 220.182 47.8225 219.725 48.6768 219.556C48.84 219.515 49.0126 219.539 49.1588 219.621C49.3051 219.703 49.4137 219.839 49.4623 219.998C49.5057 220.16 49.4833 220.333 49.3999 220.478C49.3165 220.624 49.1788 220.732 49.0163 220.778C48.4322 220.872 47.9006 221.169 47.5159 221.616C47.1311 222.062 46.918 222.629 46.9142 223.216C46.9105 223.804 47.1163 224.374 47.4953 224.825C47.8743 225.276 48.4019 225.58 48.9848 225.682L48.1765 226.293L48.3402 220.184C48.362 219.37 49.6392 219.367 49.6173 220.184V220.184Z" fill="white"/>
    <path d="M246.495 303.85C260.633 298.427 268.358 277.845 270.057 259.226C271.802 240.104 271.301 219.494 255.315 207.208" stroke="#CE1459" strokeWidth="4"/>
    <path d="M243.317 287.249C257.507 282.287 263.588 266.553 263.588 252.676C263.588 238.424 261.111 223.225 243.317 215.221" stroke="#CE1459"/>
    <path d="M237.31 318.745C247.584 319.577 259.48 308.573 267.12 296.989C274.966 285.092 282.018 271.535 276.967 258.616" stroke="#CE1459"/>
    <g filter="url(#filter1_d_23_911)">
    <path d="M849.847 240.916H442.465C432.524 240.916 424.465 248.975 424.465 258.916V433.586C424.465 443.527 432.524 451.586 442.465 451.586H849.847C859.788 451.586 867.847 443.527 867.847 433.586V258.916C867.847 248.975 859.788 240.916 849.847 240.916Z" fill="#E6E6E6"/>
    <path d="M837.389 259.038H455.489C445.548 259.038 437.489 267.097 437.489 277.038V416.597C437.489 426.538 445.548 434.597 455.489 434.597H837.389C847.331 434.597 855.389 426.538 855.389 416.597V277.038C855.389 267.097 847.331 259.038 837.389 259.038Z" fill="white"/>
    <path d="M867.847 237.493H424.465V256.181H867.847V237.493Z" fill="#CE1459"/>
    <path d="M438.622 250.217C440.498 250.217 442.019 248.569 442.019 246.536C442.019 244.503 440.498 242.855 438.622 242.855C436.745 242.855 435.224 244.503 435.224 246.536C435.224 248.569 436.745 250.217 438.622 250.217Z" fill="white"/>
    <path d="M451.646 250.217C453.522 250.217 455.043 248.569 455.043 246.536C455.043 244.503 453.522 242.855 451.646 242.855C449.769 242.855 448.248 244.503 448.248 246.536C448.248 248.569 449.769 250.217 451.646 250.217Z" fill="white"/>
    <path d="M464.953 250.217C466.986 250.217 468.634 248.569 468.634 246.536C468.634 244.503 466.986 242.855 464.953 242.855C462.92 242.855 461.272 244.503 461.272 246.536C461.272 248.569 462.92 250.217 464.953 250.217Z" fill="white"/>
    <path d="M834.922 399.485C834.922 399.485 834.922 399.485 834.922 399.485H627.835C627.663 399.485 627.498 399.417 627.376 399.295C627.255 399.173 627.187 399.008 627.187 398.835V299.33C627.187 299.158 627.255 298.992 627.376 298.871C627.498 298.749 627.663 298.68 627.835 298.68C628.007 298.68 628.171 298.749 628.293 298.871C628.414 298.992 628.483 299.158 628.483 299.33V380.186C628.483 390.127 636.542 398.186 646.483 398.186H834.922C835.094 398.186 835.259 398.254 835.381 398.376C835.502 398.498 835.57 398.663 835.57 398.835C835.57 399.008 835.502 399.173 835.381 399.295C835.259 399.417 835.094 399.485 834.922 399.485C834.922 399.485 834.922 399.485 834.922 399.485V399.485Z" fill="#3F3D56"/>
    <path d="M671.699 392.123H652.891C652.381 392.122 651.893 391.918 651.533 391.555C651.173 391.192 650.97 390.7 650.969 390.186V364.045C650.97 363.531 651.173 363.039 651.533 362.676C651.893 362.313 652.381 362.109 652.891 362.108H671.699C672.208 362.109 672.696 362.313 673.057 362.676C673.417 363.039 673.619 363.531 673.62 364.045V390.186C673.619 390.7 673.417 391.192 673.057 391.555C672.696 391.918 672.208 392.123 671.699 392.123Z" fill="#CE1459"/>
    <path d="M706.241 392.123H687.433C686.923 392.122 686.435 391.92 686.075 391.56C685.715 391.199 685.512 390.711 685.511 390.202V339.677C685.512 339.168 685.715 338.68 686.075 338.32C686.435 337.96 686.923 337.757 687.433 337.756H706.241C706.75 337.757 707.238 337.96 707.599 338.32C707.959 338.68 708.161 339.168 708.162 339.677V390.202C708.161 390.711 707.959 391.199 707.599 391.56C707.238 391.92 706.75 392.122 706.241 392.123Z" fill="#CE1459"/>
    <path d="M740.783 392.123H721.974C721.465 392.123 720.977 391.918 720.617 391.555C720.257 391.192 720.054 390.7 720.053 390.186V364.045C720.054 363.531 720.257 363.039 720.617 362.676C720.977 362.313 721.465 362.109 721.974 362.108H740.783C741.292 362.109 741.78 362.313 742.14 362.676C742.501 363.039 742.703 363.531 742.704 364.045V390.186C742.703 390.7 742.501 391.192 742.14 391.555C741.78 391.918 741.292 392.123 740.783 392.123Z" fill="#CE1459"/>
    <path d="M774.758 392.123H755.95C755.452 392.133 754.971 391.946 754.611 391.603C754.25 391.26 754.041 390.788 754.029 390.291V329.961C754.041 329.464 754.25 328.992 754.611 328.649C754.971 328.305 755.452 328.119 755.95 328.129H774.758C775.256 328.119 775.738 328.305 776.098 328.649C776.458 328.992 776.667 329.464 776.679 329.961V390.291C776.667 390.788 776.458 391.26 776.098 391.603C775.738 391.946 775.256 392.133 774.758 392.123Z" fill="#CE1459"/>
    <path d="M809.3 392.123H790.492C789.983 392.122 789.494 391.92 789.134 391.56C788.774 391.2 788.571 390.711 788.571 390.202V312.494C788.571 311.985 788.774 311.497 789.134 311.136C789.494 310.776 789.983 310.574 790.492 310.573H809.3C809.809 310.574 810.298 310.776 810.658 311.136C811.018 311.497 811.221 311.985 811.221 312.494V390.202C811.221 390.711 811.018 391.2 810.658 391.56C810.298 391.92 809.809 392.122 809.3 392.123Z" fill="#CE1459"/>
    <path d="M662.295 354.18C664.484 354.18 666.259 352.532 666.259 350.499C666.259 348.466 664.484 346.818 662.295 346.818C660.106 346.818 658.331 348.466 658.331 350.499C658.331 352.532 660.106 354.18 662.295 354.18Z" fill="#3F3D56"/>
    <path d="M696.837 329.262C699.026 329.262 700.8 327.487 700.8 325.297C700.8 323.108 699.026 321.333 696.837 321.333C694.647 321.333 692.873 323.108 692.873 325.297C692.873 327.487 694.647 329.262 696.837 329.262Z" fill="#3F3D56"/>
    <path d="M731.379 347.18C733.568 347.18 735.342 345.532 735.342 343.499C735.342 341.466 733.568 339.818 731.379 339.818C729.189 339.818 727.415 341.466 727.415 343.499C727.415 345.532 729.189 347.18 731.379 347.18Z" fill="#3F3D56"/>
    <path d="M765.354 316.803C767.543 316.803 769.318 315.028 769.318 312.838C769.318 310.649 767.543 308.874 765.354 308.874C763.165 308.874 761.39 310.649 761.39 312.838C761.39 315.028 763.165 316.803 765.354 316.803Z" fill="#3F3D56"/>
    <path d="M799.896 302.645C802.085 302.645 803.86 300.87 803.86 298.68C803.86 296.491 802.085 294.716 799.896 294.716C797.707 294.716 795.932 296.491 795.932 298.68C795.932 300.87 797.707 302.645 799.896 302.645Z" fill="#3F3D56"/>
    <path d="M742.256 339.32C736.009 346.147 725.604 347.125 718.194 341.582L707.707 333.738C701.314 328.956 692.536 328.956 686.144 333.738L663.586 350.611C663.3 350.824 662.896 350.766 662.682 350.481V350.481C662.468 350.196 662.527 349.791 662.812 349.577L686.144 332.125C692.536 327.343 701.315 327.343 707.707 332.125L718.031 339.848C725.441 345.39 735.846 344.412 742.092 337.586L764.934 312.623C765 312.552 765.08 312.496 765.17 312.459V312.459L798.814 298.921C799.145 298.788 799.521 298.948 799.654 299.278V299.278C799.788 299.609 799.627 299.986 799.296 300.119L769.587 312.073C767.087 313.079 764.845 314.633 763.026 316.621L742.256 339.32Z" fill="#3F3D56"/>
    <path d="M541.115 280.558H486.754C485.503 280.558 484.489 281.572 484.489 282.823C484.489 284.074 485.503 285.089 486.754 285.089H541.115C542.366 285.089 543.38 284.074 543.38 282.823C543.38 281.572 542.366 280.558 541.115 280.558Z" fill="#E6E6E6"/>
    <path d="M573.392 293.584H486.754C485.503 293.584 484.489 294.598 484.489 295.849C484.489 297.1 485.503 298.114 486.754 298.114H573.392C574.643 298.114 575.657 297.1 575.657 295.849C575.657 294.598 574.643 293.584 573.392 293.584Z" fill="#CE1459"/>
    <path d="M557.536 306.042H487.887C486.636 306.042 485.621 307.057 485.621 308.308C485.621 309.559 486.636 310.573 487.887 310.573H557.536C558.787 310.573 559.802 309.559 559.802 308.308C559.802 307.057 558.787 306.042 557.536 306.042Z" fill="#E6E6E6"/>
    <path d="M525.826 319.634H486.754C485.503 319.634 484.489 320.648 484.489 321.899C484.489 323.15 485.503 324.165 486.754 324.165H525.826C527.077 324.165 528.091 323.15 528.091 321.899C528.091 320.648 527.077 319.634 525.826 319.634Z" fill="#E6E6E6"/>
    <path d="M547.344 332.659H486.754C485.503 332.659 484.489 333.674 484.489 334.925C484.489 336.176 485.503 337.19 486.754 337.19H547.344C548.595 337.19 549.609 336.176 549.609 334.925C549.609 333.674 548.595 332.659 547.344 332.659Z" fill="#CE1459"/>
    <path d="M461.838 279.426H461.273C459.396 279.426 457.875 280.947 457.875 282.823C457.875 284.7 459.396 286.221 461.273 286.221H461.838C463.715 286.221 465.236 284.7 465.236 282.823C465.236 280.947 463.715 279.426 461.838 279.426Z" fill="#E6E6E6"/>
    <path d="M461.838 292.451H461.273C459.396 292.451 457.875 293.972 457.875 295.849C457.875 297.725 459.396 299.247 461.273 299.247H461.838C463.715 299.247 465.236 297.725 465.236 295.849C465.236 293.972 463.715 292.451 461.838 292.451Z" fill="#CE1459"/>
    <path d="M461.838 304.91H461.273C459.396 304.91 457.875 306.431 457.875 308.308C457.875 310.184 459.396 311.706 461.273 311.706H461.838C463.715 311.706 465.236 310.184 465.236 308.308C465.236 306.431 463.715 304.91 461.838 304.91Z" fill="#E6E6E6"/>
    <path d="M461.838 318.501H461.273C459.396 318.501 457.875 320.023 457.875 321.899C457.875 323.776 459.396 325.297 461.273 325.297H461.838C463.715 325.297 465.236 323.776 465.236 321.899C465.236 320.023 463.715 318.501 461.838 318.501Z" fill="#E6E6E6"/>
    <path d="M457.875 335.208C457.875 337.241 459.523 338.889 461.555 338.889C463.588 338.889 465.236 337.241 465.236 335.208C465.236 333.175 463.588 331.527 461.555 331.527C459.523 331.527 457.875 333.175 457.875 335.208Z" fill="#CE1459"/>
    <path d="M541.115 346.251H486.754C485.503 346.251 484.489 347.265 484.489 348.516C484.489 349.767 485.503 350.782 486.754 350.782H541.115C542.366 350.782 543.38 349.767 543.38 348.516C543.38 347.265 542.366 346.251 541.115 346.251Z" fill="#E6E6E6"/>
    <path d="M573.392 359.276H486.754C485.503 359.276 484.489 360.291 484.489 361.542C484.489 362.793 485.503 363.807 486.754 363.807H573.392C574.643 363.807 575.657 362.793 575.657 361.542C575.657 360.291 574.643 359.276 573.392 359.276Z" fill="#CE1459"/>
    <path d="M557.536 371.736H487.887C486.636 371.736 485.621 372.75 485.621 374.001C485.621 375.252 486.636 376.266 487.887 376.266H557.536C558.787 376.266 559.802 375.252 559.802 374.001C559.802 372.75 558.787 371.736 557.536 371.736Z" fill="#E6E6E6"/>
    <path d="M525.826 385.327H486.754C485.503 385.327 484.489 386.341 484.489 387.592C484.489 388.844 485.503 389.858 486.754 389.858H525.826C527.077 389.858 528.091 388.844 528.091 387.592C528.091 386.341 527.077 385.327 525.826 385.327Z" fill="#E6E6E6"/>
    <path d="M547.344 398.352H486.754C485.503 398.352 484.489 399.367 484.489 400.618C484.489 401.869 485.503 402.883 486.754 402.883H547.344C548.595 402.883 549.609 401.869 549.609 400.618C549.609 399.367 548.595 398.352 547.344 398.352Z" fill="#E6E6E6"/>
    <path d="M457.875 348.233C457.875 350.266 459.523 351.914 461.555 351.914C463.588 351.914 465.236 350.266 465.236 348.233C465.236 346.2 463.588 344.552 461.555 344.552C459.523 344.552 457.875 346.2 457.875 348.233Z" fill="#E6E6E6"/>
    <path d="M457.875 361.259C457.875 363.291 459.523 364.94 461.555 364.94C463.588 364.94 465.236 363.291 465.236 361.259C465.236 359.226 463.588 357.577 461.555 357.577C459.523 357.577 457.875 359.226 457.875 361.259Z" fill="#CE1459"/>
    <path d="M457.875 373.718C457.875 375.75 459.523 377.399 461.555 377.399C463.588 377.399 465.236 375.75 465.236 373.718C465.236 371.685 463.588 370.036 461.555 370.036C459.523 370.036 457.875 371.685 457.875 373.718Z" fill="#E6E6E6"/>
    <path d="M457.875 387.309C457.875 389.342 459.523 390.99 461.555 390.99C463.588 390.99 465.236 389.342 465.236 387.309C465.236 385.276 463.588 383.628 461.555 383.628C459.523 383.628 457.875 385.276 457.875 387.309Z" fill="#E6E6E6"/>
    <path d="M461.838 397.22H461.273C459.396 397.22 457.875 398.741 457.875 400.618C457.875 402.494 459.396 404.016 461.273 404.016H461.838C463.715 404.016 465.236 402.494 465.236 400.618C465.236 398.741 463.715 397.22 461.838 397.22Z" fill="#E6E6E6"/>
    </g>
    <defs>
    <filter id="filter0_d_23_911" x="-6.10352e-05" y="198.889" width="273.482" height="132.7" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feMorphology radius="6" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_23_911"/>
    <feOffset dy="4"/>
    <feGaussianBlur stdDeviation="3.5"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_23_911"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_23_911" result="shape"/>
    </filter>
    <filter id="filter1_d_23_911" x="418.465" y="235.493" width="455.382" height="226.094" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="4"/>
    <feGaussianBlur stdDeviation="3"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_23_911"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_23_911" result="shape"/>
    </filter>
    <linearGradient id="paint0_linear_23_911" x1="-83.6124" y1="237.865" x2="390.392" y2="278.408" gradientUnits="userSpaceOnUse">
    <stop stopColor="#CE1459" stopOpacity="0.3"/>
    <stop offset="0.49028" stopColor="#CE1459" stopOpacity="0.3"/>
    <stop offset="1" stopColor="#CE1459"/>
    </linearGradient>
    </defs>
    </svg>
  );
}

export default HeroSVG;