import React, { useState } from "react";
import Hero from "../../components/Hero";
import "./styles.css";
import ImportButton from "../../components/ImportButton";

const Home = () => {
    const [isImportButtonOpen, setIsImportButtonOpen] = useState(false)
    const title = "Você ainda não posui dados a serem tratados";
    const message =
        "Faça seu primeiro Upload para que seja gerado o seu primeiro Dashboard";

    const openImportButton = () => {
        setIsImportButtonOpen(true)
    }
    const closeImportButton = () => {
        setIsImportButtonOpen(false)
    }

    return (
        <div className="home__container">

            <div className="home__content">
                <Hero
                    title={title}
                    center={true}
                    message={message}
                    svgClassName="home-svg"
                    childrenClassName={'home-import-container'}>
                    <ImportButton
                        onImportButtonClose={closeImportButton}
                        onImportButtonOpen={openImportButton}
                        modalState={isImportButtonOpen}
                        createNew
                    />
                </Hero>
            </div>
        </div>
    )

};

export default Home;
