/* eslint-disable react-hooks/exhaustive-deps */
import { Card, Form, Input, Select, Spin } from 'antd'
import './styles.css'
import { getProducts } from '../../services/actionPlan.service'
import { useState } from 'react';
import { useEffect } from 'react';
import { getSegments, getRegions } from '../../services/dashboard.service';

const titleCase = text => {
	return text.toLowerCase()
		.split(' ')
		.map((word) => {
			return word[0]?.toUpperCase() + word.slice(1);
		}).join(' ')
}

const FormStockStrategy = ({
    plano,
    formValidated,
    nome,
    onInputName,
    quartil,
    onSelectQuartil,
    regiao,
    onSelectRegiao,
    segmento,
    onSelectSegmento,
    pecaOrEquip,
    onSelectPecaOrEquip,
    produto,
    onSelectProduto,
    tempoUltimaCompra,
    onSelectTempoUltimaCompra
}) => {

    const { Option } = Select;
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true)
    const [loadingOptionsProdutos, setLoadingOptionsProdutos] = useState(false)
    const [optionsProdutos, setOptionsProdutos] = useState([])
    const [optionsPecasOrMaquinas, setOptionsPecasOrMaquinas] = useState([
        { label: 'Peça', value: 'PECAS' },
        { label: 'Máquina', value: 'MAQUINAS' },
    ])
    const [optionsRegioes, setOptionsRegioes] = useState([])
    const [optionsSegment, setOptionsSegment] = useState([])

    const getOptionsProducts = async () => {
        setLoadingOptionsProdutos(true)
        await getProducts(pecaOrEquip)
            .then(res => { setOptionsProdutos(res.filter(item => !!item).map(value => ({ value, label: value }))) })
            .catch(err => { console.error(err) })
            .finally(() => { setLoadingOptionsProdutos(false) })
    }

    useEffect(async() => {
        try {
            setLoading(true)
            const segmentsResult = await getSegments()
            const regionsResult = await getRegions()
            
            setOptionsRegioes([
                { label: 'Todas', value: null },
                ...regionsResult.map(item => (
                    {
                        label: titleCase(item),
                        value: item
                    }
                ))
            ])
    
            setOptionsSegment([
                { label: 'Todos', value: null },
                ...segmentsResult.map(item => (
                    {
                        label: titleCase(item),
                        value: item
                    }
                ))
            ])

            await setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
        }

        
    }, [])

    useEffect(() => {
        onSelectProduto("")
        setOptionsProdutos([])
        getOptionsProducts()
    }, [pecaOrEquip])

    return (
        <Spin spinning={loading}>
            <Card style={{ width: '100%' }}>
                <Form
                    layout="vertical"
                    form={form}
                >
                    <Form.Item label="Digite um nome para a estratégia de ação​">
                        <Input value={nome} onChange={(e) => onInputName(e.target.value)}
                            status={formValidated && nome === "" ? "error" : ""}
                        />
                    </Form.Item>

                    {/* QUARTIL */}
                    {(plano === 'churn' || plano === 'relacionamento' || plano === 3) &&
                        <Form.Item label="Selecione o Quartil/Cluster do Cliente foco​">
                            <Select
                                placeholder="Selecione um item"
                                mode="multiple"
                                defaultValue={quartil}
                                status={formValidated && quartil.length === 0 ? "error" : ""}
                                style={{ width: '100%' }}
                                onChange={(value) => { onSelectQuartil(value); console.log(value) }}
                            >
                                <Option value={'todos'}>
                                    Todos
                                </Option>
                                <Option value={1}>
                                    1º Quartil
                                </Option>
                                <Option value={2}>
                                    2º Quartil
                                </Option>
                                <Option value={3}>
                                    3º Quartil
                                </Option>
                                <Option value={4}>
                                    4º Quartil
                                </Option>
                            </Select>
                        </Form.Item>
                    }

                    {(plano === 3) &&
                        <Form.Item label="Selecione a frequência de compra">
                            <Select defaultValue="0" style={{ width: '100%' }}>
                                <Option value="0">
                                    Selecione um item
                                </Option>
                                <Option value="1">
                                    Clientes na média do cluster
                                </Option>
                                <Option value="2">
                                    Clientes fora da média do cluster
                                </Option>
                                <Option value="3">
                                    Clientes na média geral
                                </Option>
                                <Option value="4">
                                    Clientes fora da média geral
                                </Option>
                            </Select>
                        </Form.Item>
                    }

                    {(plano === 'produto') &&
                        <>
                            <Form.Item label="Selecione o produto que deseja focar">
                                <Select
                                    status={formValidated && pecaOrEquip === "" ? "error" : ""}
                                    defaultValue={pecaOrEquip}
                                    style={{ width: '100%' }}
                                    onChange={(value) => onSelectPecaOrEquip(value)}
                                >   
                                    <Option value={null}>
                                        Selecione um item
                                    </Option>
                                    {optionsPecasOrMaquinas.map(
                                        ({ label, value }, index) => <Option key={`option-produto-${index}`} value={value}>{label}</Option>
                                    )}
                                </Select>
                            </Form.Item>

                            <Form.Item label={`Selecione ${pecaOrEquip === "PECAS" ? "Peça" : "Equipamento ou máquina"}`}>
                                <Select disabled={pecaOrEquip === null}
                                    status={formValidated && produto === "" ? "error" : ""}
                                    defaultValue={produto}
                                    style={{ width: '100%' }}
                                    onChange={(value) => onSelectProduto(value)}
                                    loading={loadingOptionsProdutos}
                                >
                                    <Option value={null}>
                                        Selecione um item
                                    </Option>
                                    {optionsProdutos.map(
                                        ({ label, value }, index) => <Option key={`option-produtos-${index}`} value={value}>{label}</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </>
                    }

                    {(plano === 3) &&
                        <Form.Item label="Selecione o ticket médio">
                            <Select defaultValue="0" style={{ width: '100%' }}>
                                <Option value="0">
                                    Selecione um item
                                </Option>
                                <Option value="1">
                                    Clientes abaixo da TM
                                </Option>
                                <Option value="2">
                                    Clientes abaixo e na média
                                </Option>
                                <Option value="3">
                                    Clientes acima da TM
                                </Option>
                                <Option value="4">
                                    Todos
                                </Option>
                            </Select>
                        </Form.Item>
                    }

                    {/* REGIÃO */}
                    {(plano === 'churn' || plano === 'relacionamento' || plano === 'produto' || plano === 3) &&
                        <Form.Item label="Selecione a região de preferência dessa ação​">
                            <Select defaultValue={regiao} style={{ width: '100%' }}
                                status={formValidated && regiao === "" ? "error" : ""}
                                onChange={(value) => onSelectRegiao(value)}
                            >
                                {optionsRegioes.map(
                                    ({ label, value }, index) => <Option key={`option-regiao-${index}`} value={value}>{label}</Option>
                                )}
                            </Select>
                        </Form.Item>
                    }

                    {/* SEGMENTO */}
                    {(plano === 'churn' || plano === 'relacionamento' || plano === 'produto') &&
                        <Form.Item label="Selecione o segmento foco​​">
                            <Select defaultValue={segmento} style={{ width: '100%' }}
                                status={formValidated && segmento === "" ? "error" : ""}
                                onChange={(value) => onSelectSegmento(value)}
                            >
                                {optionsSegment.map(
                                    ({ label, value }, index) => <Option key={`option-segment-${index}`} value={value}>{label}</Option>
                                )}
                            </Select>
                        </Form.Item>
                    }

                    {plano === 'produto' &&
                        <Form.Item label="Selecione o tempo desde a última compra​​">
                            <Select
                                status={formValidated && tempoUltimaCompra === "" ? "error" : ""}
                                defaultValue={tempoUltimaCompra}
                                style={{ width: '100%' }}
                                onChange={(value) => onSelectTempoUltimaCompra(value)}
                            >
                                <Option value="3">
                                    Mais de 3 meses
                                </Option>
                                <Option value="6">
                                    Mais de 6 meses
                                </Option>
                                <Option value="9">
                                    Mais de 9 meses (risco de churn)
                                </Option>
                            </Select>
                        </Form.Item>
                    }
                </Form>
            </Card>
        </Spin>
    )
}

export default FormStockStrategy