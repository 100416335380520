import React, { useState } from "react";
import { useSelector } from "react-redux";
import { signInAuth, signUpAuth } from "../../auth";
import { isAValidSign } from "../../utils/sign-error";
import { useHistory } from "react-router-dom";
import LoadingImage from '../../assets/images/loading.svg'
import "./styles.css";
// import UserAction from '../../store/actions/UserAction'
// import FacebookSVG from '../Icons/FacebookSVG'
// import GoogleSVG from '../Icons/GoogleSVG'
// import AppleSVG from '../Icons/AppleSVG'

const signInfoInitialState = {
    name: "",
    email: "",
    password: "",
    passwordConfirmation: "",
};

export default function SignForm() {
    let history = useHistory()
    const [formPage, setFormPage] = useState(true);
    const [loading, setLoading] = useState(false);
    const [signInfo, setSignInfo] = useState(signInfoInitialState);
    const signUser = useSelector((state) => state.UserReducer);

    const handleChangeFormPage = (page) => {
        setFormPage(page);
    };

    const handleForm = async () => {
        setLoading(true);
        if (isAValidSign(formPage, signInfo)) {
            formPage
            ? signInAuth(signInfo.email, signInfo.password, history)
            : signUpAuth(signInfo);
        }
        setTimeout(() => {
            setLoading(false)
        }, 5000);
    };
    
    return (
        <div className="form__container">
            <div className="form__header">
                <div>
                    <h2 htmlFor="sign-in" className={formPage ? "active title" : "title"}>
                        Bem-vindo de volta
                    </h2>
                    <h4 className="subtitle">
                        Por favor, insira seu e-mail e senha
                    </h4>
                </div>
                {/* <label htmlFor="sign-up" className={!formPage ? 'active' : ''}>Registrar-se</label> */}
                <input
                    hidden
                    type="radio"
                    name="form-page"
                    id="sign-in"
                    onChange={() => handleChangeFormPage(true)}
                />
                <input
                    hidden
                    type="radio"
                    name="form-page"
                    id="sign-up"
                    onChange={() => handleChangeFormPage(false)}
                />
            </div>

            <div className="form__content">
                {!formPage && (
                    <>
                        <label htmlFor="name">Nome</label>
                        <input
                            id="name"
                            type="text"
                            value={signInfo.name}
                            onChange={(e) =>
                                setSignInfo({ ...signInfo, name: e.target.value })
                            }
                        />
                    </>
                )}

                <label htmlFor="email">Email</label>
                <input
                    id="email"
                    value={signInfo.email}
                    onChange={(e) => setSignInfo({ ...signInfo, email: e.target.value })}
                    type="text"
                />
                <label htmlFor="password">Senha</label>
                <input
                    id="password"
                    value={signInfo.password}
                    onChange={(e) =>
                        setSignInfo({ ...signInfo, password: e.target.value })
                    }
                    type="password"
                />

                {!formPage && (
                    <>
                        <label htmlFor="password">Confirmar senha</label>
                        <input
                            id="password"
                            type="password"
                            value={signInfo.passwordConfirmation}
                            onChange={(e) =>
                                setSignInfo({
                                    ...signInfo,
                                    passwordConfirmation: e.target.value,
                                })
                            }
                        />
                    </>
                )}

                <button onClick={handleForm}>
                    {loading ? (
                        <img src={LoadingImage} alt="loading" />
                    ) : "Entrar"}
                </button>
                <span className="error break-words">{signUser?.error}</span>
            </div>
            {/* <h3 className="continue-with">Ou continuar com ...</h3>

            <div className="form__footer">
                <button className="btn social-btn facebook">
                    <span>
                        <FacebookSVG />
                    </span>
                    Continuar com Facebook</button>
                <button className="btn social-btn google">
                    <span>
                        <GoogleSVG />
                    </span>
                    Continuar com Google</button>
                <button className="btn social-btn apple">
                    <span>
                        <AppleSVG />
                    </span>
                    Continuar com Apple</button>
            </div> */}
        </div>
    );
}
