export const setDashboard = dashboard => {
    return {
        type: 'SET_DASHBOARD',
        payload: dashboard
    }
}

const getDashboard = () => {
    return {
        type: 'GET_DASHBOARD'
    }
}

const DashboardAction = {setDashboard, getDashboard}

export default DashboardAction
