export const formatNumber = num =>
  new Intl.NumberFormat("pt-BR", { maximumFractionDigits: 2 }).format(num);

export const formatCurrency = num => {
 try {
  if (num !== 0 && !num) return ' - '
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    maximumFractionDigits: 2
  }).format(num);
 } catch {
  return '-'
 }
}

export const formatPercent = num => {
  try {
    return new Intl.NumberFormat("pt-BR", {
      style: "percent",
      maximumFractionDigits: 2
    }).format(num);
  } catch(e) {
    return ' - '
  }
}

export const formatDate = data => new Intl.DateTimeFormat('pt-BR', {timeZone: 'UTC'}).format(new Date(data));
