import './styles.css'
import { Tooltip } from 'antd'

const CustomDropdown = (props) => {

  return (
    <Tooltip title={props.text} color="#230B5C" placement="rightTop">
      <div className="custom-dropdown">
        <button className="custom-dropdown-content">
          <i className='bx bx-info-circle'></i>
        </button>
      </div>
    </Tooltip>
  )
}
export default CustomDropdown
