import { useState } from 'react'
import EmptyData from '../EmptyData'
import NotificationList from '../NotificationList'
import NotificationViewer from '../NotificationViewer'
import './styles.css'

const NotificationsBoard = ({ notifications }) => {
  
  const [notificationSelected, setNotificationSelected] = useState(null)
  
  if (notifications && notifications.length === 0)
    return <EmptyData text="Não há Insights do Guru!" />
  
  return (
    <div className="notifications-board">
      <NotificationList
        notifications={notifications}
        notificationSelected={notificationSelected}
        onClickAction={setNotificationSelected}
      />
      <NotificationViewer notification={notificationSelected} />
    </div>
  )
}
export default NotificationsBoard
