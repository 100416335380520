import store from "../store";
import AuthAction from "../store/actions/AuthAction";
import UserAction from "../store/actions/UserAction";
import axios from "axios";

const refreshTokenAuth = async (token, history) => {
  console.log("fetching...")
  await axios
    .post("https://data-driven.azurewebsites.net/token", { token })
    .then((response) => {
      localStorage.setItem(
        "auth",
        JSON.stringify({ ...response.data, createdAt: new Date() })
      );
      store.dispatch(AuthAction.setToken(response.data));
      return response.data.access_token;
    })
    .catch((err) => {
      store.dispatch(UserAction.setError({ error: err.message }));
      history.push("/");
    })
};

export const checkRefreshToken = (history) => {
  console.log("checking...")
  const auth = localStorage.getItem("auth");
  const { createdAt, expires_in, refresh_token } = JSON.parse(auth);

  const init = new Date(createdAt).getTime();
  const now = new Date().getTime();

  if (now - init > expires_in * 1000) {
    console.log("will fetch")
    refreshTokenAuth(refresh_token, history);
  }
};
