export const initialUserState = {
    id: '',
    name: '',
    username: '',
    error: ''
}

const UserReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_ERROR':
            return {
                ...state,
                error: action.payload.error
            }
        case 'SET_USER':
            return {
                ...state,
                id: action.payload.id,
                name: action.payload.name,
                username: action.payload.username,
            }

        case 'SET_INITIAL_USER':
            return {
                state: initialUserState
            }
        default:
            return state
    }
}

export default UserReducer