import { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import "./styles.css";
import { getUserDashboards } from "../../services/dashboard.service";
import Home from "../../components/Home";
import Dashboard from "../../components/Dashboard";
import { Button, Card, Col, Row } from "antd";
import Title from "antd/lib/typography/Title"
import StatusCard from "../../components/StatusCard";
import { HomeOutlined, AppstoreOutlined, NotificationOutlined, ProjectOutlined } from '@ant-design/icons';
import Chart from 'react-apexcharts'
import CardGeneral from "../../components/CardGeneral";
import ModalStockStrategy from "../../components/ModalStockStrategy";

const Inicio = () => {
    const [hasDashboard, setHasDashboard] = useState(null);
    const [dashboard, setDashboard] = useState(null);
    const [loading, setLoading] = useState(false)

    const chartBar = {
        series: [{
            name: 'Net Profit',
            data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
        }, {
            name: 'Revenue',
            data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
        }, {
            name: 'Free Cash Flow',
            data: [35, 41, 36, 26, 45, 48, 52, 53, 41]
        }],
        options: {
            chart: {
                type: 'bar',
                height: 350
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded'
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            xaxis: {
                categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
            },
            yaxis: {
                title: {
                    text: '$ (thousands)'
                }
            },
            fill: {
                opacity: 1
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return "$ " + val + " thousands"
                    }
                }
            }
        },
    }

    const chartPie = {
        series: [44, 55, 13, 43, 22],
        options: {
            chart: {
                width: 380,
                type: 'pie',
            },
            labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        },
    }

    useEffect(() => {

        console.log('Início')
        setLoading(true);
        getUserDashboards()
            .then(res => {
                setDashboard(res)
                setHasDashboard(!isEmpty(res))
            })
            .catch(err => { console.error(err, err.response) })
            .finally(() => { setLoading(false) })
    }, [])

    function isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }

    return (
        <Layout>
            <h2 style={{ marginBottom: '1rem' }}>Cenário de peças</h2>
            <Row gutter={16}>
                <Col className="gutter-row" span={8}>
                    <StatusCard
                        count={"R$ 12.299.004,90"}
                        title={"Potencial de vendas 2022"}
                    />
                </Col>
                <Col className="gutter-row" span={8}>
                    <StatusCard
                        count={"R$ 4.505,13"}
                        title={"Ticket médio das peças"}
                    />
                    {/* hasMoreInfo={true}
                        action={item.action}
                        textMoreInfo={item.textMoreInfo} */}
                </Col>
                <Col className="gutter-row" span={8}>
                    <StatusCard
                        count={"425 Clientes"}
                        title={"Clientes potenciais - Ano"}
                    />
                </Col>
            </Row>
            <Row gutter={16} style={{ marginBottom: '2rem' }}>
                <Col className="gutter-row" span={8}>
                    <StatusCard
                        style={{ height: "100%" }}
                        count={"R$ 1.024.917,08"}
                        title={"Potencial de vendas desse mês"}
                    />
                </Col>
                <Col className="gutter-row" span={8}>
                    <Row gutter={16} style={{ width: "100%" }}>
                        <Col className="gutter-row" span={12}>
                            <StatusCard
                                style={{ height: "100%" }}
                                count={"2.730"}
                                title={"Quantidades de peças potencial"}
                            />
                        </Col>
                        <Col className="gutter-row" span={12}>
                            <StatusCard
                                style={{ height: "100%" }}
                                count={"730"}
                                title={"Máquinas e equipamentos"}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col className="gutter-row" span={8}>
                    <StatusCard
                        style={{ height: "100%" }}
                        count={"73"}
                        title={"Clientes potenciais - Quentes (Mês Atual)"}
                    />
                </Col>
            </Row>
            <Row gutter={16}>
                <Col className="gutter-row" span={16}>
                    <CardGeneral
                        title="Potencial de venda por mês (Em milhares)"
                    >
                        <Chart
                            options={chartBar?.options}
                            series={chartBar?.series}
                            type='bar'
                            height='190px'
                        />
                    </CardGeneral>
                </Col>
                <Col className="gutter-row" span={8}>
                    <CardGeneral
                        title="Proporção por região"
                    >
                        <Chart
                            options={chartPie?.options}
                            series={chartPie?.series}
                            type='pie'
                            height='300px'
                        />
                    </CardGeneral>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col className="gutter-row" span={16}>
                    <CardGeneral
                        title="Potencial de venda por mês - Proporção máquinas e equipamentos"
                    >
                        <Chart
                            options={chartBar?.options}
                            series={chartBar?.series}
                            type='bar'
                            height='190px'
                        />
                    </CardGeneral>
                </Col>
                <Col className="gutter-row" span={8}>
                    <CardGeneral
                        title="Proporção por peça"
                    >
                        <Chart
                            options={chartPie?.options}
                            series={chartPie?.series}
                            type='pie'
                            height='300px'
                        />
                    </CardGeneral>
                </Col>
            </Row>
            <Row gutter={16}>
                {/* <Title style={{ marginRight: 10 }} level={5}>Plano de ação</Title> */}
                <ModalStockStrategy style={{ marginLeft: 10 }} textButton="Ativar clientes potenciais" />
            </Row>
        </Layout>
    )

};

export default Inicio;
