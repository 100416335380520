import './styles.css'

const Button = ({ children, text, border, onClick }) => {
  const managerClass = () => {
    let classList = 'button-component'
    if (border) classList += ' button-component--outline'
    return classList
  }

  return (
    <button
      onClick={() => {
        onClick()
      }}
      className={managerClass()}
    >
      {!!children ? children : text}
    </button>
  )
}

export default Button