const initialState = {
    dashboard: {}
}

const DashboardReducer = (state = initialState, action) => {
    switch(action.type) {
        case 'SET_DASHBOARD':
            return {
                ...state,
                dashboard: action.payload
            }
        default:
            return state
    }
}

export default DashboardReducer