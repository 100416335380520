import React from 'react'
import CustomDropdown from '../CustomDropdown'

import './styles.css'

const StatusCard = props => {
    return (
        <div className='status-card' style={props.style} onClick={() => { !!props.action && props.action() }}>
            <div className="status-card__icon">
                {!!props.slotIcon ? props.slotIcon : <i className={props.icon}></i>}
            </div>
            <div className="status-card__info" style={props.styleBody}>
                {(!!props.children || !!props.count) &&
                    <h4>
                        {props.children ? props.children : props.count}
                    </h4>
                }
                <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{props.title}</span>
                {props.inner}
            </div>
            {!!props.textMoreInfo && <div className="more-info">
                <CustomDropdown text={props.textMoreInfo} />
            </div>
            }
        </div>
    )
}

export default StatusCard