export const setLoading = loading => {
    return {
        type: 'SET_LOADING',
        payload: loading
    }
}

const getLoading = () => {
    return {
        type: 'GET_LOADING'
    }
}

const LoadingAction = {setLoading, getLoading}

export default LoadingAction
