const setError = error => {
    return {
        type: 'SET_ERROR',
        payload: error
    }
}

const setUser = user => {
    return {
        type: 'SET_USER',
        payload: user
    }
}
const setInitialUser = user => {
    return {
        type: 'SET_INITIAL_USER',
        payload: user
    }
}

const getUser = () => {
    return {
        type: 'GET_USER'
    }
}

const exportDefault = {
    setUser,
    setError,
    getUser,
    setInitialUser
}

export default exportDefault