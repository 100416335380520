import { setNotificationAsRead } from '../../services/notification.service'
import './styles.css'

const NotificationList = ({ notifications, notificationSelected, onClickAction }) => {

  return (
    <div className="notification-list">
      <div className="notification-list__content">
        { notifications && notifications.map((item) => {
            return (
              <div className={ `${item.id === notificationSelected?.id ? 'notification-selected' : ''} ${!item.seen ? 'notification-unread': ''}` }
                key={`not-${item.id}`}
                onClick={() => {
                  if (!item.seen) {
                    setNotificationAsRead(item.id)
                    item.seen = true
                  }
                  onClickAction(item)
                }}
              >
                <i className="bx bx-package"></i>{item.title}
                <div className="item-data">
                  {
                    !!item.updatedAt && new Intl.DateTimeFormat("pt-BR", {
                        year: "numeric",
                        month: "numeric",
                        day: "2-digit"
                    }).format(new Date(item.updatedAt))
                  }
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}
export default NotificationList
