import React from "react";

export default function ExcelSVG() {
    return (
        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.6773 0.883615C12.5134 0.74849 12.2964 0.692427 12.0865 0.73124L0.5865 2.88749C0.245812 2.95074 0 3.24686 0 3.5933V19.4058C0 19.7508 0.245812 20.0484 0.5865 20.1116L12.0865 22.2679C12.1296 22.2765 12.1756 22.2808 12.2188 22.2808C12.3841 22.2808 12.5479 22.2233 12.6773 22.1155C12.8426 21.9789 12.9375 21.7748 12.9375 21.5621V1.43705C12.9375 1.22286 12.8426 1.02018 12.6773 0.883615ZM11.5 20.6952L1.4375 18.8092V4.18986L11.5 2.30386V20.6952Z" fill="white" />
            <path d="M22.2812 3.59332H12.2188C11.822 3.59332 11.5 3.91532 11.5 4.31207C11.5 4.70882 11.822 5.03082 12.2188 5.03082H21.5625V17.9683H12.2188C11.822 17.9683 11.5 18.2903 11.5 18.6871C11.5 19.0838 11.822 19.4058 12.2188 19.4058H22.2812C22.678 19.4058 23 19.0838 23 18.6871V4.31207C23 3.91532 22.678 3.59332 22.2812 3.59332Z" fill="white" />
            <path d="M15.0938 6.46831H12.2188C11.822 6.46831 11.5 6.79031 11.5 7.18706C11.5 7.58381 11.822 7.90581 12.2188 7.90581H15.0938C15.4905 7.90581 15.8125 7.58381 15.8125 7.18706C15.8125 6.79031 15.4905 6.46831 15.0938 6.46831Z" fill="white" />
            <path d="M15.0938 9.34331H12.2188C11.822 9.34331 11.5 9.66531 11.5 10.0621C11.5 10.4588 11.822 10.7808 12.2188 10.7808H15.0938C15.4905 10.7808 15.8125 10.4588 15.8125 10.0621C15.8125 9.66531 15.4905 9.34331 15.0938 9.34331Z" fill="white" />
            <path d="M15.0938 12.2183H12.2188C11.822 12.2183 11.5 12.5403 11.5 12.9371C11.5 13.3338 11.822 13.6558 12.2188 13.6558H15.0938C15.4905 13.6558 15.8125 13.3338 15.8125 12.9371C15.8125 12.5403 15.4905 12.2183 15.0938 12.2183Z" fill="white" />
            <path d="M15.0938 15.0933H12.2188C11.822 15.0933 11.5 15.4153 11.5 15.8121C11.5 16.2088 11.822 16.5308 12.2188 16.5308H15.0938C15.4905 16.5308 15.8125 16.2088 15.8125 15.8121C15.8125 15.4153 15.4905 15.0933 15.0938 15.0933Z" fill="white" />
            <path d="M19.4062 6.46831H17.9688C17.572 6.46831 17.25 6.79031 17.25 7.18706C17.25 7.58381 17.572 7.90581 17.9688 7.90581H19.4062C19.803 7.90581 20.125 7.58381 20.125 7.18706C20.125 6.79031 19.803 6.46831 19.4062 6.46831Z" fill="white" />
            <path d="M19.4062 9.34331H17.9688C17.572 9.34331 17.25 9.66531 17.25 10.0621C17.25 10.4588 17.572 10.7808 17.9688 10.7808H19.4062C19.803 10.7808 20.125 10.4588 20.125 10.0621C20.125 9.66531 19.803 9.34331 19.4062 9.34331Z" fill="white" />
            <path d="M19.4062 12.2183H17.9688C17.572 12.2183 17.25 12.5403 17.25 12.9371C17.25 13.3338 17.572 13.6558 17.9688 13.6558H19.4062C19.803 13.6558 20.125 13.3338 20.125 12.9371C20.125 12.5403 19.803 12.2183 19.4062 12.2183Z" fill="white" />
            <path d="M19.4062 15.0933H17.9688C17.572 15.0933 17.25 15.4153 17.25 15.8121C17.25 16.2088 17.572 16.5308 17.9688 16.5308H19.4062C19.803 16.5308 20.125 16.2088 20.125 15.8121C20.125 15.4153 19.803 15.0933 19.4062 15.0933Z" fill="white" />
            <path d="M9.88573 13.9016L4.85448 8.15163C4.58998 7.85119 4.13717 7.82244 3.83961 8.08406C3.54061 8.34569 3.51042 8.79994 3.77204 9.0975L8.80329 14.8475C8.94561 15.0099 9.14398 15.0933 9.34379 15.0933C9.51198 15.0933 9.68017 15.0344 9.81817 14.9151C10.1172 14.6534 10.1474 14.2006 9.88573 13.9016Z" fill="white" />
            <path d="M9.78509 7.33944C9.47171 7.09362 9.02034 7.15256 8.77596 7.4645L3.74471 13.9332C3.50177 14.2466 3.55784 14.6994 3.87121 14.9424C4.00346 15.0444 4.15871 15.0933 4.31252 15.0933C4.52527 15.0933 4.73802 14.9984 4.8789 14.8173L9.91015 8.34856C10.1545 8.03375 10.0985 7.58237 9.78509 7.33944Z" fill="white" />
        </svg>
    )
}