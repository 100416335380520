import './styles.css'

const EmptyData = (props) => {
  return (
    <div className="all-center">
      <span>{ props.text }</span>
    </div>
  )
}
export default EmptyData
