import { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import "./styles.css";
import { getUserDashboards } from "../../services/dashboard.service";
import Home from "../../components/Home";
import Dashboard from "../../components/Dashboard";

const Inicio = () => {

    const [hasDashboard, setHasDashboard] = useState(null);
    const [dashboard, setDashboard] = useState(null);
    const [loading, setLoading] = useState(false)

    useEffect(() => {

        console.log('Início')
        setLoading(true);
        getUserDashboards()
            .then(res => {
                setDashboard(res)
                setHasDashboard(!isEmpty(res))
            })
            .catch(err => { console.error(err, err.response) })
            .finally(() => { setLoading(false) })
    }, [])

    function isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }

    return (
        <Layout>
            {loading ? <div></div> :
                <div>
                    {!hasDashboard ? <Home /> : <Dashboard dashboard={dashboard} />}
                </div>
            }
        </Layout>
    )

};

export default Inicio;
