const initialState = {
  log: [],
};

const LogReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_LOG":
      return {
        ...state,
        log: action.payload,
      };
    default:
      return state;
  }
};

export default LogReducer;
