
import CustomDropdown from '../CustomDropdown'
import './styles.css'

const CardGeneral = ({ title, children, moreInfo, style, moreActions }) => {
    return (
        <div className="card" style={style}>
            <div className="card__header d-flex justify-between">
                <div>
                    <h3>{title}</h3>
                    {!!moreInfo && <CustomDropdown text={moreInfo} />}
                </div>
                {!!moreActions &&
                    <div>
                        {moreActions}
                    </div>
                }

            </div>
            <div className='card__body'>
                {children}
            </div>
        </div>
    )
}

export default CardGeneral