import React from 'react';
import Logo from '../../assets/images/logo-complete.png';

import './styles.css'

const Navbar = () => {
  return <>
        <div className="nav__container container">
           <img src={Logo} alt="Logo" className='logo-navbar' />
        </div>
  </>;
}

export default Navbar;