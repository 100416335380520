import './styles.css'
import { getActionPlans, getPreviewActionPlans, createActionPlan, getActionPlanById, updateActionPlan, removeActionPlan } from '../../services/actionPlan.service'
import store from "../../store";
import LoadingAction from "../../store/actions/LoadingAction";
import Layout from '../../components/Layout'
import CButton from '../../components/Button';
import { Table, Tooltip, Modal, Card, Button, message, Input } from 'antd'
import { useEffect, useState } from 'react';
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import StockStrategyView from '../../components/StockStrategyView';
import StepByStepStockStrategy from '../../components/StepByStepStockStrategy';
import confirm from 'antd/lib/modal/confirm';
import ModalStockStrategy from '../../components/ModalStockStrategy';

const StockStrategy = () => {

  const [isModalVisibleView, setIsModalVisibleView] = useState(false)
  const [isModalVisibleRename, setIsModalVisibleRename] = useState(false)

  const [dataSource, setDataSource] = useState([])
  const [loading, setLoading] = useState(false)
  const [stockStrategyView, setStockStrategyView] = useState({})

  const openModalRename = (value) => {
    setStockStrategyView(value)
    setIsModalVisibleRename(true);
  }

  const handleCancelView = () => {
    setIsModalVisibleView(false);
    setStockStrategyView({})
  };

  const handleCancelRename = () => {
    setIsModalVisibleRename(false);
    setStockStrategyView({})
  };

  // ASYNCS
  const getList = async () => {
    setLoading(true);
    await getActionPlans().then(res => {
      setDataSource(
        res.map(item => ({ ...item, object: item }))
      )
      setLoading(false)
    }).catch(err => { console.error(err) })
      .finally(() => { })
  }

  const updatePlan = async (value) => {
    await updateActionPlan(value).then(res => {
      setIsModalVisibleRename(false)
      getList()
    }).catch(err => { console.error(err) })
  }

  const deletePlan = async (id) => {
    await removeActionPlan(id)
      .then((result) => {
        message.success("Estratégia removida com sucesso!")
        getList()
      }).catch((err) => {
        console.error(err)
        message.error("Estratégia não pode ser removida, tente novamente!")
      }).finally(() => {

      });
  }

  const showModalView = (value) => {
    setIsModalVisibleView(true)
    setStockStrategyView(value)
  }

  const showDeleteConfirm = (id) => {
    confirm({
      title: 'Você tem certeza que deseja remover esta estratégia?',
      icon: <ExclamationCircleOutlined />,
      content: 'Os dados serão removidos e não poderão ser resgatados',
      okText: 'Sim, remover',
      okType: 'danger',
      cancelText: 'Não',
      onOk() {
        deletePlan(id)
      },
    });
  };

  const actionColumn = (value) => {
    try {
      return (
        <>
          <Tooltip title="Editar">
            <Button onClick={(e) => { e.stopPropagation(); openModalRename(value) }} type="primary" icon={<EditOutlined />} />
          </Tooltip>
          <Tooltip title="Remover">
            <Button danger icon={<DeleteOutlined />} onClick={(e) => { e.stopPropagation(); showDeleteConfirm(value.id) }} />
          </Tooltip>
        </>
      )
    } catch (error) {
      console.error(error)
    }
  }

  const columns = [
    {
      title: 'Nome da estratégia',
      dataIndex: 'name',
      key: 'name',

    },
    {
      title: "Tipo",
      dataIndex: 'type',
      key: 'type',
      align: 'center'
    },
    {
      title: 'Ações',
      dataIndex: 'object',
      key: 'object',
      render: actionColumn,
      width: 120,
      align: 'center'
    }
  ];

  // HOOKS
  useEffect(() => {
    getList()
  }, [])

  return (
    <Layout>
      <section className="stock-strategy">

        <article className="stock-strategy__title">
          <h2>Estratégias de ações</h2>
          {/* <CButton onClick={showModal}><i className="bx bx-plus"></i>Adicionar</CButton> */}
          <ModalStockStrategy textButton="Adicionar" afterSaveAction={getList} />
        </article>

        <article className="stock-strategy__content">
          <Card title="" bordered={true} style={{ width: '100%' }}>

            <Table dataSource={dataSource} columns={columns} loading={loading}
              onRow={(record) => {
                return {
                  onClick: () => {
                    showModalView(record)
                  }
                };
              }}
            />
          </Card>

          <Modal
            title={stockStrategyView?.name}
            visible={isModalVisibleView}
            width={750}
            footer={null}
            onCancel={handleCancelView}
          >
            <StockStrategyView data={stockStrategyView} />
          </Modal>

          <Modal
            title="Renomear estratégia"
            visible={isModalVisibleRename}
            width={750}
            okText="Renomear"
            onCancel={handleCancelRename}
            onOk={() => { updatePlan(stockStrategyView) }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ marginBottom: '0.75rem' }}>Nome da estratégia</span>
              <Input value={stockStrategyView?.name} onChange={(e) => setStockStrategyView({ ...stockStrategyView, name: e.target.value })} />
            </div>
          </Modal>
        </article>
      </section>
    </Layout>
  )
}

export default StockStrategy
