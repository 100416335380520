import { Card } from "antd"
import Paragraph from "antd/lib/typography/Paragraph"
import "./styles.css"

const SelectTypeStrategy = ({ value, onSelect }) => {

    const stylesCard = {
        width: '33.33%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }

    const cardsSelect = [
        { value: 'churn', text: 'Redução / Controle de Churn' },
        { value: 'produto', text: 'Aumento de Venda de um Produto Específico' },
        { value: 'relacionamento', text: 'Desenvolvimento de Relacionamento com Clientes Mais Valiosos' }
    ]

    const getClassName = (valor) => {
        return value === valor ? 'card-selected' : ''
    }

    return (
        <div className="content-card-step-2">
            {
                cardsSelect.map(item => {
                    return (
                        <Card hoverable
                            className={getClassName(item.value)}
                            style={stylesCard}
                            onClick={() => onSelect(item.value)}
                        >
                            <Paragraph> {item.text} </Paragraph>
                        </Card>
                    )
                })
            }
        </div>
    )
}

export default SelectTypeStrategy 