import React from 'react'

import { BrowserRouter, Switch, Route } from 'react-router-dom';

// import Home from './pages/Home'
// import Dashboard from './pages/Dashboard'
import Customers from './pages/Customers'
import Notifications from './pages/Notifications'
import StockStrategy from './pages/EstrategiaAcao'
import PartsScenario from './pages/PartsScenario'
import PartsModule from './pages/PartsModule'
import SignIn from './pages/SignIn'
import Inicio from './pages/Inicio'

const Routes = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route path='/' exact component={SignIn} />
                <Route path='/inicio' exact component={Inicio} />
                {/* <Route path='/home' component={Home} />
                <Route path='/dashboard' component={Dashboard} /> */}
                <Route path='/customers' component={Customers} />
                <Route path='/notifications' component={Notifications} />
                <Route path='/stock-strategy' component={StockStrategy} />
                <Route path='/parts-scenario' component={PartsScenario} />
                <Route path='/parts-module' component={PartsModule} />
            </Switch>
        </BrowserRouter>
    )
}

export default Routes
