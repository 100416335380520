import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, Link } from "react-router-dom";
// import Sidebar from '../Sidebar'
import TopNav from '../TopNav'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../store/actions/UserAction'
import ThemeAction from '../../store/actions/ThemeAction'
import LoadingAction from '../../store/actions/LoadingAction'
import { initialUserState } from '../../store/reducers/UserReducer'
import { verifyIfWasChangedUserState } from '../../utils/state-changes'
import jwt from 'jwt-decode'
import './styles.css'
import AuthAction from '../../store/actions/AuthAction';
import logo from '../../assets/images/logo.png'
// import LoadingView from '../LoadingView';
// import LogAlert from '../LogAlert';
import { checkRefreshToken } from '../../auth/refreshToken';

import { HomeOutlined, AppstoreOutlined, NotificationOutlined, ProjectOutlined, BarChartOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
const { Header, Content, Footer, Sider } = Layout;



const Componente = (props) => {
    let history = useHistory()
    // const themeReducer = useSelector(state => state.ThemeReducer)
    // const loadingReducer = useSelector(state => state.LoadingReducer.loading)
    // const logReducer = useSelector(state => state.LogReducer.log)
    const dispatch = useDispatch()

    const usePathname = () => {
        const location = useLocation();
        return location.pathname.substring(1);
    }

    const items = [
        {
            label: 'Dashboard',
            key: 'inicio',
            icon: <HomeOutlined />,
            route: '/inicio'
        },
        {
            label: 'Insights do Guru',
            key: 'notifications',
            icon: <NotificationOutlined />,
            route: '/notifications'
        },
        {
            label: 'Planos de ação do GURU',
            key: 'stock-strategy',
            icon: <ProjectOutlined />,
            route: '/stock-strategy'
        },
        // { label: 'Cenários de Peças', key: 'parts-scenario', icon: <AppstoreOutlined />, route: '/parts-scenario' },
        { label: 'Módulo de Peças', key: 'parts-module', icon: <BarChartOutlined />, route: '/parts-module' },
    ];

    useEffect(() => {
        const themeClass = localStorage.getItem('themeMode', 'theme-mode-light')
        const colorClass = localStorage.getItem('colorMode', 'theme-mode-light')

        dispatch(ThemeAction.setMode(themeClass))
        dispatch(ThemeAction.setColor(colorClass))
    }, [dispatch])

    useEffect(() => {
        checkRefreshToken(history)

        const auth = JSON.parse(localStorage.getItem('auth', null))
        const token = auth?.access_token || ''
        let user = token ? jwt(token) : initialUserState

        const curUser = {
            id: user?.oid || user.id,
            name: user.name,
            username: user?.username || ''
        }

        dispatch(UserAction.setUser(curUser));
        dispatch(AuthAction.setToken(auth))
        !verifyIfWasChangedUserState(initialUserState, curUser) && history.push('/')
        setTimeout(() => {
            dispatch(LoadingAction.setLoading(false))
        }, 3000);
    }, [dispatch, history])

    const [collapsed, setCollapsed] = useState(true);

    return (

        // <div className={`layout ${themeReducer.mode} ${themeReducer.color} container`}>
        //     <Sidebar />
        //     <div className="layout__content">
        //         <TopNav />
        //         <div className="layout__content-main">
        //             {children}
        //         </div>
        //     </div>
        //     {loadingReducer ? <LoadingView /> : <></>}
        //     <LogAlert list={logReducer} />
        // </div>

        <Layout style={{ minHeight: '100vh' }}>
            <Sider className="sider-layout" collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
                <div className="logo-layout">
                    <img src={logo} alt="company logo" />
                </ div>

                <Menu className="sider-layout-menu" theme="dark" defaultSelectedKeys={[usePathname()]} mode="inline">
                    {
                        items.map((item) => {
                            return (
                                <Menu.Item key={item.key} icon={item.icon}>
                                    <Link to={item.route}>
                                        <span>{item.label}</span>
                                    </Link>
                                </Menu.Item>
                            )
                        })
                    }
                </Menu>
            </Sider>
            <Layout className="site-layout">
                <Header
                    className="site-layout-background"
                    style={{ padding: 0 }}
                >
                    <TopNav />
                </Header>
                <Content
                    style={{
                        margin: '0 0',
                    }}
                >
                    <div
                        className="site-layout-background"
                        style={{
                            padding: 24,
                            minHeight: 360,
                        }}
                    >
                        {props.children}
                    </div>
                </Content>
                <Footer
                    style={{
                        textAlign: 'center',
                    }}
                >
                    Guru ©2022
                </Footer>
            </Layout>
        </Layout>
    )
}

export default Componente
