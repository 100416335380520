// import CommentsBoard from '../CommentsBoard'
import { useEffect } from 'react'
import EmptyData from '../EmptyData'
import { AlertOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import './styles.css'
import { formatCurrency, formatDate, formatNumber, formatPercent } from '../../utils/format';
import ModalStockStrategy from '../ModalStockStrategy';

const NotificationViewer = ({ notification }) => {

  useEffect(() => {
    try {
      if (!!notification) {
        const a = document.createElement('a');

        const data = !!notification.list.csv ? notification.list.csv : notification.list['csv:'];

        a.href = 'data:attachment/csv,' + encode(data);
        a.target = '_blank';
        a.download = 'data.csv';
        a.className = 'ant-btn ant-btn-primary';
        a.append(!!notification?.list?.csv ? 'Baixar CSV' : '');

        const csvDownload = document.getElementById('download-csv');

        if (!!csvDownload) csvDownload.innerHTML = '';
        csvDownload?.appendChild(a);
      }
    } catch (error) {
      console.log(error)
    }

  }, [notification?.list?.csv, notification])

  function formatCorrectType({ type }) {
    const formats = {
      currency: formatCurrency,
      number: (value) => formatNumber(value) !== 'NaN' ? formatNumber(value) : value,
      date: formatDate,
      percentage: (value) => formatPercent(value / 100),
      text: capitalizeFirstLetter
    }

    return !!formats[type] ? formats[type] : (value) => value
  }

  function encode(text) {
    var textIsInvalid = text === undefined ||
      typeof text !== 'string' ||
      text.length < 1;

    if (textIsInvalid) throw new Error('Cannot encodeURIComponent(string),' + ' string is invalid');

    return encodeURIComponent(text);
  }

  function capitalizeFirstLetter(value) {
    try {
      console.log("capitalize", value)
      return !!value ? value.charAt(0)?.toUpperCase() + value.slice(1).toLowerCase() : value;
    } catch (error) {
      return value
    }
  }

  if (!notification) {
    return <EmptyData text="Selecione um Insight do Guru!" />
  }

  function generateColumns() {
    const baseColumns = [
      ...notification?.list?.header.map(item => {
        return {
          ...item,
          render: formatCorrectType(item)
        }
      }),
      {
        dataIndex: 'subiu', title: '', key: 'subiu', render: (value) => {
          console.log(value);
          if (value === undefined) return
          return value ? <ArrowUpOutlined style={{ color: 'green' }} /> : <ArrowDownOutlined style={{ color: 'red' }} />
        }
      },
    ]

    return notification.title !== "Novo insight sobre o seu Ticket Médio" ? [{ dataIndex: 'index', title: '#', key: 'index' }, ...baseColumns] : baseColumns
  }

  return (
    <div className="notification-viewer">
      <div className="notification-viewer__info">
        <h2 className="notification-viewer__title">{notification.title}</h2>
        <div className="notification-viewer__time">
          {
            !!notification.createdAt && new Intl.DateTimeFormat("pt-BR", {
              year: "numeric",
              month: "numeric",
              day: "2-digit",
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric'
            }).format(new Date(notification.createdAt))
          }
        </div>
        <div style={{ margin: '1.5rem 0 0.5rem', fontSize: '1rem' }}>
          {notification.subject}
        </div>
        <div style={{ margin: '1.5rem 0 0.5rem', fontSize: '1rem' }}>
          {notification.explanation}
        </div>
        {!!notification.reminder && <div style={{ fontSize: '0.75rem', opacity: 0.65, padding: '0.5rem', border: '1px solid #cccc', borderRadius: '0.5rem' }}>
          <AlertOutlined /> Lembrete: {notification.reminder}
        </div>}
        <div style={{ marginTop: '1.5rem', border: '1px solid #cccc', padding: '0.5rem', borderRadius: '0.5rem' }}>
          <Table
            dataSource={
              notification?.list?.body?.map((item, index) => (
                {
                  index: index + 1,
                  ...item
                }
              ))
            }
            columns={generateColumns()}
          />
        </div>
        <div style={{ display: 'flex', marginTop: '1rem' }}>
          <div id="download-csv" style={{ marginRight: '1rem' }}></div>
          {
            notification.title === 'Confira o Top 20 Produtos mais vendidos da última semana' &&
            <ModalStockStrategy textButton="Aumentar vendas de um produto específico" />
          }
          {
            notification.title !== 'Confira o Top 20 Produtos mais vendidos da última semana' &&
            <ModalStockStrategy textButton="Ações sugeridas do GURU" />
            // <Button type="primary">Ações Sugeridas do GURU</Button>
          }
        </div>
        {/* <h3 className="notification-viewer__title-comment">Anotações</h3> */}
      </div>
      {/* <CommentsBoard comments={notification.comments} id={notification.id} /> */}
    </div>
  )

}
export default NotificationViewer
