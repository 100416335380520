import store from '../store'
import UserAction from '../store/actions/UserAction'

export const isAValidSign = (formPage, signInfo) => {
    const { name, email, password, passwordConfirmation } = signInfo
    if (formPage) {
        return (isAValidEmail(email)
            && isAValidPassword(password))
    } else {
        return (isAValidEmail(email)
            && isAValidPassword(password)
            && isAValidPasswordConfirmation(password, passwordConfirmation))
            && isAValidName(name)
    }
}

const isAValidEmail = email => {
    // eslint-disable-next-line 
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return (true)
    }

    store.dispatch(UserAction.setError({ error: 'Email não é válido' }))
    return false
}
const isAValidPassword = password => {
    if (password.length >= 6) {
        return true
    }
    store.dispatch(UserAction.setError({ error: 'A senha precisa ter pelo menos 6 caractéres' }))
    return false
}
const isAValidPasswordConfirmation = (password, passwordConfirmation) => {
    if (passwordConfirmation === password) {
        return true
    }
    store.dispatch(UserAction.setError({ error: 'A senha e a confirmação de senha são diferentes' }))
    return false
}
const isAValidName = name => {
    if (name.length > 0) {
        return true
    }
    store.dispatch(UserAction.setError({ error: 'Você deve informar um nome válido' }))
    return false
}