import React, { useRef } from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { createDashboard, updateDashboard } from "../../services/dashboard.service";
import DashboardAction from "../../store/actions/DashboardAction";
import { checkDashboard } from "../../utils/check-dashboard";
import Button from "../Button";
import ExcelSVG from "../ExcelSVG";

import './styles.css'

const ImportButton = ({ buttonTtitle, onImportButtonOpen, onImportButtonClose, modalState, createNew, update, dashboardId }) => {
    const uploadFileInput = useRef()
    const dispatch = useDispatch()
    const history = useHistory()

    const handleOpenImportButton = () => {
        onImportButtonOpen()
    }

    const handleCloseImportButton = () => {
        onImportButtonClose()
    }

    const handleUploadFileClick = () => {
        uploadFileInput.current.value = null;
        uploadFileInput.current.click()
    }
    const handleUploadFileChange = async ({ target }) => {
        const file = target.files[0]

        createNew &&
            await createDashboard({ data: file })
                .then(res => {
                    localStorage.setItem('dashboard', JSON.stringify(res?.data))
                    JSON.stringify(res) === '{}' ? localStorage.setItem('hasDashboard', false) : localStorage.setItem('hasDashboard', true)
                    dispatch(DashboardAction.setDashboard(res))
                })
                .catch((response) => {
                    console.log("ERROR", response)
                })
                .finally(() => {
                    // checkDashboard(history)
                    handleCloseImportButton()
                    document.location.reload(true);
                })

        update &&
            await updateDashboard(dashboardId, file)
                .then(res => {
                    try {
                        console.log(res, !!res)
                        const data = !!res ? JSON.stringify(res.data) : null
                        localStorage.setItem('dashboard', data)
                        JSON.stringify(res) === '{}' ? localStorage.setItem('hasDashboard', false) : localStorage.setItem('hasDashboard', true)
                        dispatch(DashboardAction.setDashboard(res))
                    } catch (error) { console.error(error) }
                })
                .finally(() => {
                    // checkDashboard(history)   
                    handleCloseImportButton()
                })
    }
    return (
        <>
            {/* <button className="button" onClick={handleOpenImportButton}>
                <i className="bx bx-plus"></i>
                {buttonTtitle || "Criar novo Dashboard"}
            </button> */}
            <Button onClick={handleOpenImportButton}>
                <i className="bx bx-plus"></i> Importar dados
            </Button>

            <Modal
                isOpen={modalState}
                onRequestClose={handleCloseImportButton}
                overlayClassName={'react-modal-overlay'}
                className={'react-modal-content'}
            >
                <h2>Importe  seu documento</h2>
                <div className="step">
                    <h3>Passo 1</h3>
                    <p>Para importar o seu documento, baixe o modelo do arquivo</p>

                    <a href="https://c3csoftware-my.sharepoint.com/:x:/g/personal/tulio_sales_c3csoftware_com_br/EbwPTy20A4BClh8eCb9yRZIBnUnQKiusFsptco8qjTtVvw?e=kemx9f&download=1" download className="button">Baixar arquivo <span className="icon">{<ExcelSVG />}</span></a>

                </div>
                <div className="step">
                    <h3>Passo 2</h3>
                    <p>Digite ou importe do seu sistema antigo as informações de contas a pagar da plnilha (arquivo do passo 1)</p>
                </div>
                <div className="step">
                    <h3>Passo 3</h3>
                    <p>Importe o arquivo modelo atualizado</p>

                    <button href="/#" className="button" onClick={handleUploadFileClick}>Importar <span className="icon">{<ExcelSVG />}</span></button>
                </div>
                <input className="upload-input-file" type="file" name="upload-dash" ref={uploadFileInput} onChange={handleUploadFileChange} accept=".xls,.xlsx,.csv" />
            </Modal>
        </>
    );
}

export default ImportButton