import { formatCurrency, formatNumber, formatPercent } from "./format";

export const months = {
  1: "Jan",
  2: "Fev",
  3: "Mar",
  4: "Abr",
  5: "Mai",
  6: "Jun",
  7: "Jul",
  8: "Ago",
  9: "Set",
  10: "Out",
  11: "Nov",
  12: "Dez"
};

export const chartOptionsData = data => {
  try {
    return {
      series: [
        {
          type: "line",
          name: "Quantidade de compras",
          // data: []
          data: Object.values(data.analise_mensal.quantidadeItemVenda)
        },
        {
          type: "column",
          name: "Valor das vendas",
          // data: []
          data: Object.values(data.analise_mensal.valorTotalVenda)
        }
      ],
      options: {
        color: ["#6ab04c", "#2980b9"],
        chart: {
          background: "transparent"
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "smooth"
        },
        xaxis: {
          categories: Object.keys(data.analise_mensal.quantidadeItemVenda).map(
            m => months[m]
          )
          // categories: []
        },
        yaxis: [
          {
            title: {
              text: "Quantidade de compras"
            },
            labels: {
              formatter: function (val, index) {
                return formatNumber(val);
              }
            }
          },
          {
            opposite: true,
            title: {
              text: "Valor das vendas"
            },
            labels: {
              formatter: function (val, index) {
                return formatCurrency(val);
              }
            }
          }
        ],
        legend: {
          position: "bottom"
        },
        grid: {
          show: false
        }
      }
    };
  } catch (e) {
    console.error(e)
    return
  }
};

export const abcChartOptionsData = data => {
  return {
    series: [
      {
        type: "line",
        name: "Participação",
        data: data.analise_abc.participacao
      },
      {
        type: "line",
        name: "Participação acumulada",
        data: data.analise_abc.participacao_acc
      }
    ],
    options: {
      color: ["#6ab04c", "#2980b9"],
      chart: {
        background: "transparent",
        animations: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "smooth"
      },
      xaxis: {
        categories: data.analise_abc.abc,
        tickAmount: 50,
        labels: {
          hideOverlappingLabels: true
        }
      },
      yaxis: [
        {
          title: {
            text: "Participação"
          },
          labels: {
            formatter: function (val, index) {
              return `${val.toFixed(3).replace(".", ",")}%`;
            }
          }
        },
        {
          opposite: true,
          title: {
            text: "Participação acumulada"
          },
          labels: {
            formatter: function (val, index) {
              return `${val.toFixed(2).replace(".", ",")}%`;
            }
          }
        }
      ],
      legend: {
        position: "bottom"
      },
      grid: {
        show: false
      }
    }
  };
};

export const freqChartOptionsData = data => {
  return {
    series: [
      {
        type: "line",
        name: "Número de clientes",
        data: Object.values(data.frequencia_consumo)
      }
    ],
    options: {
      color: ["#6ab04c", "#2980b9"],
      chart: {
        background: "transparent"
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "smooth"
      },
      xaxis: {
        categories: Object.keys(data.frequencia_consumo)
      },
      legend: {
        position: "bottom"
      },
      grid: {
        show: false
      }
    }
  };
};

export const paretoChartOptionsData = data => {
  return {
    series: [
      {
        name: "Pareto",
        data: [
          data.analise_pareto.quant_pareto,
          data.analise_pareto.faturamento_pareto
        ]
      },
      {
        name: "Não-pareto",
        data: [
          data.clientes_unicos - data.analise_pareto.quant_pareto,
          data.faturamento_total - data.analise_pareto.faturamento_pareto
        ]
      }
    ],
    options: {
      color: ["#6ab04c", "#2980b9"],
      chart: {
        background: "transparent",
        stacked: true,
        stackType: "100%"
      },
      xaxis: {
        categories: ["Quantidade", "Faturamento"]
      },
      yaxis: {
        labels: {
          formatter: function (val, index) {
            return val.toFixed(2).replace(".", ",");
          }
        }
      },
      legend: {
        position: "bottom"
      },
      grid: {
        show: false
      }
    }
  };
};

export const percentileChartOptionsData = data => {
  return {
    series: [
      {
        type: "bar",
        name: "Faturamento",
        data: Object.values(data.analise_quartil.vendas_por_percentil)
      }
    ],
    options: {
      color: ["#6ab04c", "#2980b9"],
      chart: {
        background: "transparent"
      },
      dataLabels: {
        enabled: false
      },
      yaxis: {
        labels: {
          formatter: function (val, index) {
            return formatCurrency(val);
          }
        }
      },
      legend: {
        position: "bottom"
      },
      grid: {
        show: false
      }
    }
  };
};

export const produtoChartOptionsData = data => {
  const { produtos } = data?.data;
  return {
    options: {
      labels: produtos ? Object.keys(produtos) : [],
      yaxis: {
        labels: {
          formatter: function (value) {
            return formatPercent(value);
          }
        },
      },
    },
    series: produtos ? Object.values(produtos) : []
  };
};

export const produtoTipoChartOptionsData = data => {
  const grafico_produtos = [...data?.data?.grafico_produtos];
  const [{ categories }] = grafico_produtos.splice(grafico_produtos.length - 1);


  return {
    series: grafico_produtos,
    options: {
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        }
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0
          }
        }
      }],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10
        },
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return formatCurrency(value);
          }
        },
      },
      xaxis: {
        type: 'string',
        categories,
        labels: {
          formatter: function (value) {
            return `${months[Number(value.split('-')[1])]}/${value.split('-')[0].substring(2)}`;
          }
        },
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        position: 'right',
        offsetY: 40
      },
      fill: {
        opacity: 1
      }
    }
  }
};

export const chartTreeMap = data => {

  const { faturamento_produtos, participacao, abc, produtos_a, produtos_b, produtos_c } = data?.data?.abc_produtos
  const { functionClick } = data

  console.log("functionClick", functionClick)

  const color = {
    "A": "#00E396",
    "B": "#FEB019",
    "C": "#FF4560"
  }

  return {
    series: [
      {
        data: participacao.map((item, index) => ({ ...item, abc: abc[index] }))
      }
    ],
    options: {
      legend: {
        show: false
      },
      yaxis: {
        labels: {
          formatter: function (value, index) {
            return `${formatPercent(value)}  (${formatCurrency(faturamento_produtos[index?.dataPointIndex])})`;
          }
        }
      },
      chart: {
        height: 350,
        type: 'treemap',
        events: {
          dataPointSelection: (event, chartContext, config) => {
            const type = config.w.config.series[0].data[config.dataPointIndex].abc
            if (!!functionClick) functionClick(type)
          }
        }
      },
      title: {
        text: 'Curva ABC de Produtos'
      },
      colors: abc.map(item => color[item]),
      plotOptions: {
        treemap: {
          distributed: true,
          enableShades: true,
          shadeIntensity: 0.5,
        }
      }
    },
    table: {
      produtos_a, produtos_b, produtos_c
    }
  };
};

export const vendaRegiaoChartOptionsData = data => {
  const { particiapacao_regiaoCliente } = data?.data;
  const functionClick = data?.functionClick;

  return {
    series: !!particiapacao_regiaoCliente ? Object.values(particiapacao_regiaoCliente) : [],
    options: {
      labels: !!particiapacao_regiaoCliente ? Object.keys(particiapacao_regiaoCliente) : [],
      yaxis: {
        labels: {
          formatter: function (value) {
            return formatPercent(value / 100);
          }
        },
      },
      chart: {
        events: {
          dataPointSelection: (event, chartContext, config) => {
            const type = config.w.config.series[0].data[config.dataPointIndex].abc
            if (!!functionClick) functionClick(type)
          }
        }
      }
    },
  };
};

export const vendaRegiaoQuantidadeChartOptionsData = data => {
  const { particiapacao_regiaoCliente_qtd_vendas } = data?.data;
  const functionClick = data?.functionClick;

  return {
    series: !!particiapacao_regiaoCliente_qtd_vendas ? Object.values(particiapacao_regiaoCliente_qtd_vendas) : [],
    options: {
      labels: !!particiapacao_regiaoCliente_qtd_vendas ? Object.keys(particiapacao_regiaoCliente_qtd_vendas) : [],
      yaxis: {
        labels: {
          formatter: function (value) {
            return formatPercent(value);
          }
        },
      },
      chart: {
        events: {
          dataPointSelection: (event, chartContext, config) => {
            const type = config.w.config.series[0].data[config.dataPointIndex].abc
            if (!!functionClick) functionClick(type)
          }
        }
      }
    },
  };
};