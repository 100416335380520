import './styles.css'

import { useState, useEffect } from 'react';
import CardGeneral from '../CardGeneral'
import Chart from 'react-apexcharts'

import {
    formatCurrency, formatPercent
} from '../../utils/format'
import { Modal, Table } from 'antd';

const AnaliseProdutoDashboard = (
    {
        explanationChart,
        loading,
        Loading,
        produtoChartOptions,
        chartOptions,
        themeReducer,
        produtoTipoChartOptions,
        dataTreeMap,
        typeAbcProduct
    }
) => {

    const [isModalOpen, setIsModalOpen] = useState(false)

    const columns = [
        {
            title: 'Produto',
            dataIndex: 'PRODUTO',
            key: 'PRODUTO',
        },
        {
            title: 'Faturamento Total',
            dataIndex: 'faturamento_total',
            key: 'faturamento_total',
            render: formatCurrency
        },
        {
            title: 'Participação',
            dataIndex: 'participacao',
            key: 'participacao',
            render: formatPercent
        },
    ];

    useEffect(() => {
        if (!!typeAbcProduct)
            setIsModalOpen(true);
    }, [typeAbcProduct])

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <CardGeneral title="Análise de produto">
            <div className="row">
                <div className='col-7'>
                    <CardGeneral
                        title="Receita por grupo de produto"
                        moreInfo="Participação de receita por tipo de produto comercializado, demonstrando o comportamento de vendas no decorrer do ano/mês"
                    >
                        {/* // moreInfo={explanationChart.analiseMensal} */}
                        {!loading ?
                            <Chart
                                options={produtoTipoChartOptions?.options}
                                series={produtoTipoChartOptions?.series}
                                type='bar'
                                height='200px'
                            /> : <div className='loading-item__container'>
                                <img className='loading-item' src={Loading} alt='Loading' />
                            </div>
                        }
                    </CardGeneral>
                </div>

                <div className='col-5'>
                    <CardGeneral
                        title="Receita por grupo de produto"
                        moreInfo="Participação acumulada de receita por tipo de produto comercializado"
                    >
                        {!loading ?
                            <Chart
                                options={produtoChartOptions?.options}
                                series={produtoChartOptions?.series}
                                type='pie'
                                height='200px'
                            /> : <div className='loading-item__container'>
                                <img className='loading-item' src={Loading} alt='Loading' />
                            </div>
                        }
                    </CardGeneral>
                </div>
            </div>
            <div className='col-12'>
                <CardGeneral
                    title="Receita por tipo de produto"
                    moreInfo="Gráfico com curva ABC de produtos mais comercializados no período"
                >
                    {!loading ?
                        <Chart
                            options={dataTreeMap.options}
                            series={dataTreeMap.series}
                            type="treemap"
                            height={350}
                        /> : <div className='loading-item__container'>
                            <img className='loading-item' src={Loading} alt='Loading' />
                        </div>
                    }
                </CardGeneral>
            </div>
            <div className='col-12'>
                <CardGeneral
                    title="Análise Mensal de Faturamento e Quantidade de Vendas"
                    moreInfo={explanationChart.analiseMensal}
                >
                    {chartOptions && !loading ?
                        <Chart
                            options={themeReducer === 'theme-mode-dark' ? {
                                ...chartOptions.options,
                                theme: { mode: 'dark' }
                            } : {
                                ...chartOptions.options,
                                theme: { mode: 'light' }
                            }
                            }
                            series={chartOptions.series}
                            type='line'
                            height='200px'
                        /> : <div className='loading-item__container'>
                            <img className='loading-item' src={Loading} alt='Loading' />
                        </div>
                    }
                </CardGeneral>
            </div>
            <Modal title={`Produtos ${typeAbcProduct}`}
                subti
                visible={isModalOpen}
                onOk={closeModal}
                onCancel={closeModal}
                okText={'Fechar'}
                cancelButtonProps={{ style: { display: 'none' } }}
                width={780}
            >
                {
                    typeAbcProduct === "A" && <Table
                        dataSource={dataTreeMap.table.produtos_a}
                        columns={columns}
                    />
                }
                {
                    typeAbcProduct === "B" && <Table
                        dataSource={dataTreeMap.table.produtos_b}
                        columns={columns}
                    />
                }
                {
                    typeAbcProduct === "C" && <Table
                        dataSource={dataTreeMap.table.produtos_c}
                        columns={columns}
                    />
                }
            </Modal>
        </CardGeneral>
    );
};

export default AnaliseProdutoDashboard;
