import { Card, Col, Row, Slider } from "antd"
import Title from "antd/lib/typography/Title"
import { formatCurrency, formatNumber, formatPercent } from "../../utils/format"
import "./styles.css"

const PreviewResult = ({ data, salesConversionGoal, defaultValueSlider, onChangeValueSlider }) => {
    return (
        <>
            <div className="content-card-step-2">
                <Card hoverable style={{ width: '50%' }}>
                    <Title level={5} type="secondary">Potencial Capturável de​</Title>
                    <Title level={1}>{formatCurrency(data?.potencial_capturavel)}</Title>
                </Card>
                <Card hoverable style={{ width: '50%' }}>
                    <Title level={5} type="secondary">Quantidade de clientes impactados</Title>
                    <Title level={1}>{formatNumber(data?.clientes_afetados_num)}</Title>
                </Card>
            </div>
            <div className="content-card-step-2">
                <Card bordered style={{ flex: 1 }}>
                    <Title level={5} type="secondary">Meta de conversão de vendas</Title>
                    <Row style={{ marginTop: '2rem' }}>
                        <Col span={16}>
                            <Slider marks={{ 0: '0%', 100: '100%' }} onChange={(value) => onChangeValueSlider(value)} defaultValue={defaultValueSlider} />
                        </Col>
                        <Col span={8}>
                            <Title level={3}>{formatCurrency(data?.potencial_capturavel * salesConversionGoal / 100)}</Title>
                        </Col>
                    </Row>
                </Card>
            </div>
        </>
    )
}

export default PreviewResult