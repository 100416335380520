import store from "../store";
import axios from "axios";

export const getNotifications = async (quant) => {
  const token = store.getState().AuthReducer?.token?.access_token;

  return await axios
    .get(`https://data-driven.azurewebsites.net/notification/?quant=${!!quant ? quant : ''}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(res => res.data)
    .catch(e => console.error(e));
};

export const setNotificationAsRead = async (id) => {
  const token = store.getState().AuthReducer?.token?.access_token;

  return await axios
    .patch(`https://data-driven.azurewebsites.net/notification/${id}/seen`, {}, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(res => res.data)
    .catch(e => console.error(e));
};

export const createComment = async ({ id, comment }) => {
  const token = store.getState().AuthReducer.token.access_token;

  return await axios
    .post(
      `https://data-driven.azurewebsites.net/notification/${id}/comment`,
      { body: comment },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    .then(res => res.data)
    .catch(e => console.error(e));
};
