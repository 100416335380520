import store from "../store";
import AuthAction from "../store/actions/AuthAction";
import UserAction from "../store/actions/UserAction";
import jwt from "jwt-decode";
import axios from "axios";
import { toast } from "react-toastify";

export const signInAuth = async (username, password, history) => {
  await axios
    .post("https://data-driven.azurewebsites.net/auth", { username, password })
    .then(response => {
      try {
        localStorage.setItem(
          "auth",
          JSON.stringify({ ...response.data, createdAt: new Date() })
        );
        store.dispatch(AuthAction.setToken(response.data));
        return response.data.access_token;
      } catch (error) {
        console.error(error);
      }
    })
    .catch(err => {
      toast.error("E-mail/Senha incorretos. Digite suas credenciais novamente!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    })
    .then(token => {
      let user = jwt(token);
      const curUser = {
        id: user.oid,
        name: user.name,
        username
      };
      localStorage.setItem("token", token);
      store.dispatch(UserAction.setUser(curUser));
      history.push("/inicio");
    })
    .catch(err => {
      store.dispatch(UserAction.setError({ error: err.message }));
    })
};

export const signUpAuth = async ({ name, email, password }) => {
  try {
    const curUser = {
      uid: "registeredUid",
      name: "registeredName",
      email: "registeredEmail@email"
    };
    store.dispatch(UserAction.setUser(curUser));
  } catch (err) {
    store.dispatch(UserAction.setError({ error: err.message }));
  }
};

export const signOutAuth = async (history) => {
  store.dispatch(UserAction.setInitialUser());
  localStorage.removeItem("auth");
  history.push("/");

};
