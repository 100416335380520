import store from "../store";
import axios from "axios";

export const getPartsModule = async (params) => {
  const token = store.getState().AuthReducer?.token?.access_token;

  return await axios
    .get(`https://data-driven.azurewebsites.net/parts_module/`, {
      params,
      headers: {
        Authorization: `Bearer ${token}`
      },
    })
    .then(res => res.data)
    .catch(e => console.error(e));
};



