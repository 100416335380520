import { Button, message, Steps } from 'antd'
import { getPreviewActionPlans } from '../../services/actionPlan.service';
import FormStockStrategy from '../FormStockStrategy';
import PreviewResult from '../PreviewResult';
import PreviewResultList from '../PreviewResultList';
import SelectTypeStrategy from '../SelectTypeStrategy';
import './styles.css'
import { toast } from 'react-toastify';

const StepByStepStockStrategy = ({
    plano,
    onSelectPlano,
    formValidated,
    setFormValidated,
    nome,
    onInputName,
    pecaOrEquip,
    onSelectPecaOrEquip,
    quartil,
    onSelectQuartil,
    regiao,
    onSelectRegiao,
    segmento,
    onSelectSegmento,
    preview,
    setPreview,
    salesConversionGoal,
    onChangeSalesConversionGoal,
    current,
    setCurrent,
    createPlan,
    produto,
    onSelectProduto,
    tempoUltimaCompra,
    onSelectTempoUltimaCompra
}) => {

    const { Step } = Steps;

    const steps = [
        {
            title: 'Passo',
            content: <SelectTypeStrategy value={plano} onSelect={onSelectPlano} />
        },
        {
            title: 'Passo',
            content: (
                <FormStockStrategy
                    plano={plano}
                    formValidated={formValidated}
                    nome={nome}
                    onInputName={onInputName}
                    quartil={quartil}
                    onSelectQuartil={onSelectQuartil}
                    regiao={regiao}
                    onSelectRegiao={onSelectRegiao}
                    segmento={segmento}
                    onSelectSegmento={onSelectSegmento}
                    pecaOrEquip={pecaOrEquip}
                    onSelectPecaOrEquip={onSelectPecaOrEquip}
                    produto={produto}
                    onSelectProduto={onSelectProduto}
                    tempoUltimaCompra={tempoUltimaCompra}
                    onSelectTempoUltimaCompra={onSelectTempoUltimaCompra}
                />
            ),
        },
        {
            title: 'Passo',
            content: (
                <PreviewResult
                    data={preview}
                    salesConversionGoal={salesConversionGoal}
                    defaultValueSlider={30}
                    onChangeValueSlider={onChangeSalesConversionGoal}
                />
            )
        },
        {
            title: 'Passo',
            content: <PreviewResultList csvContent={preview} />
        },
    ];

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const validForm = () => {
        setFormValidated(true)
        if (plano === 'churn' || plano === 'relacionamento') {
            const isValid = !!nome && quartil.length > 0
            return isValid
        } else if (plano === 'produto') {
            const isValid = !!nome && !!produto && !!tempoUltimaCompra
            return isValid
        }
        return false
    }


    const getPreview = async () => {
        const prepObj = {
            plano,
            quartil: quartil.includes('todos') ? [1,2,3,4] : quartil,
            regiao,
            segmento,
            isPeca: pecaOrEquip === 'PECAS',
            produto,
            meses: Number(tempoUltimaCompra)
        }

        if (plano !== 'produto') {
            delete prepObj.produto
            delete prepObj.isPeca
            delete prepObj.meses
        } else {
            prepObj.quartil = [1, 2, 3, 4]
        }

        return await getPreviewActionPlans(prepObj)
            .then(res => {
                setPreview(
                    {
                        clientes_afetados: res.clientes_afetados,
                        clientes_afetados_num: res.clientes_afetados_num,
                        key_accounts: res.key_accounts,
                        mais_propensao: res.mais_propensao,
                        menos_propensao: res.menos_propensao,
                        potencial_capturavel: isNaN(res.potencial_capturavel) ? 0 : res.potencial_capturavel
                    }
                )
                return res
            })
            .catch(err => { console.error(err); return null })
    }

    const managerNextPrev = () => {
        return {
            0: {
                next: () => !!plano ? next() : message.error('Selecione uma estratégia!'),
                prev: () => { }
            },
            1: {
                next: async () => {
                    if (validForm()) {
                        try {
                            const result = await getPreview()
                            console.log("RESULT", result)
                            if(!!result) {
                                if(!result.clientes_afetados_num && !result.potencial_capturavel) {
                                    toast.warn("Não há clientes afetados e potencial capturável com essas configurações", {
                                        position: "top-center",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined
                                    });
                                } else { next() }
                            }
                        } catch (error) {
                            console.log(error)
                        }
                        
                    } else {
                        message.error('Preencha todos os campos!')
                    }
                },
                prev: () => { prev() }
            },
            2: {
                next: () => { next() },
                prev: () => { prev() }
            },
            3: {
                next: () => { },
                prev: () => { prev() }
            }
        }
    }

    return (
        <>
            <Steps current={current}>
                {steps.map(item => (
                    <Step key={item.title} title={item.title} />
                ))}
            </Steps>

            <div>
                <br />
                {steps[current].content}
            </div>

            <br />

            <div className="steps-action">
                {current > 0 ? (
                    <Button style={{ margin: '0 8px' }} onClick={() => managerNextPrev()[current].prev()}>
                        Anterior
                    </Button>
                ) : <div></div>}
                {current < steps.length - 1 && (
                    <Button type="primary" onClick={() => managerNextPrev()[current].next()}>
                        Próximo
                    </Button>
                )}
                {current === steps.length - 1 && (
                    <Button type="primary" onClick={createPlan}>
                        Salvar
                    </Button>
                )}
            </div>
        </>
    )

}

export default StepByStepStockStrategy