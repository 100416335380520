import Chart from 'react-apexcharts'
import CardGeneral from '../CardGeneral'
import StatusCard from '../StatusCard'
import { formatCurrency, formatNumber, formatPercent } from '../../utils/format'
import './styles.css'
import { Modal, Table } from 'antd'
import { useEffect, useState } from 'react'

const AnaliseClienteDashboard = ({
    explanationChart,
    paretoChartOptions,
    loading,
    themeReducer,
    Loading,
    paretoAnalises,
    dashboardReducer,
    percentileChartOptions,
    quartilAnalises,
    customerList,
    customerListChart,
    vendaPorRegiao,
    vendaPorRegiaoQuantidade,
    produtosRegiao
}) => {

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [clientSelected, setClientSelected] = useState(null);
    const [chartPieClientSelected, setChartPieClientSelected] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [regiaoProdutoSelected, setRegiaoProdutoSelected] = useState("")

    const titleCase = text => {
        try {
            return text.toLowerCase()
            .split(' ')
            .map((word) => {
                return word[0]?.toUpperCase() + word.slice(1);
            }).join(' ')
        } catch (error) {
            return ''
        }
    }

    const columnsVendaRegiao = [
        {
            title: 'Produto',
            dataIndex: 'PRODUTO',
            key: 'PRODUTO',
            render: () => '-'
        },
        {
            title: 'Quantidade de Compra',
            dataIndex: 'Qtd_Compra',
            key: 'Qtd_Compra',
            render: formatNumber
        },
        {
            title: 'Valor Venda',
            dataIndex: 'Valor_Venda',
            key: 'Valor_Venda',
            render: formatCurrency
        },
    ];

    const columns = [
        {
            title: '',
            dataIndex: 'idIndex',
            key: 'idIndex',
        },
        {
            title: 'Cliente',
            dataIndex: 'nomeCliente',
            key: 'nomeCliente',
            render: titleCase
        },
        {
            title: 'Quartil',
            dataIndex: 'Quartil',
            key: 'Quartil',
        },
        {
            title: 'Valor de vendas',
            dataIndex: 'valorTotalVenda',
            key: 'valorTotalVenda',
            render: formatCurrency
        },
        {
            title: 'Total de itens comprados',
            dataIndex: 'Frequencia_Compra',
            key: 'Frequencia_Compra',
        },
        {
            title: 'Última compra',
            dataIndex: 'Data_Ultima_Compra',
            key: 'Data_Ultima_Compra',
        },
        {
            title: 'Último produto comprado',
            dataIndex: 'Ultimo_Item_Prod',
            key: 'Ultimo_Item_Prod',
        },
    ];

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const closeModalRegiao = () => {
        setIsModalOpen(false);
    };

    const showClick = (event, chartContext, config) => {
        const label = config.w.config.labels[config.dataPointIndex]
        setRegiaoProdutoSelected(label)
        setIsModalOpen(true)
    }

    useEffect(() => {
        setChartPieClientSelected(
            {
                options: {
                    labels: !!customerListChart[clientSelected?.cnpjCliente] ? Object.keys(customerListChart[clientSelected?.cnpjCliente]) : [],
                    yaxis: {
                        labels: {
                            formatter: function (value) {
                                return formatCurrency(value);
                            }
                        },
                    },
                },
                series: !!customerListChart[clientSelected?.cnpjCliente] ? Object.values(customerListChart[clientSelected?.cnpjCliente]) : []
            }
        )
    }, [clientSelected])

    return (
        <CardGeneral title="Análise de cliente">
            <div className="row">
                {/* VENDAS POR REGIÃO */}
                <div className='col-6'>
                    <CardGeneral
                        title="Participação de vendas por região"
                    >
                        {/* moreInfo="Participação acumulada de receita por tipo de produto comercializado" */}
                        {!loading ?
                            <Chart
                                options={
                                    {
                                        ...vendaPorRegiao?.options,
                                        chart: {
                                            events: {
                                                dataPointSelection: showClick
                                            }
                                        }
                                    }
                                }
                                series={vendaPorRegiao?.series}
                                type='pie'
                                height='200px'
                            /> : <div className='loading-item__container'>
                                <img className='loading-item' src={Loading} alt='Loading' />
                            </div>
                        }
                    </CardGeneral>
                </div>
                <div className='col-6'>
                    <CardGeneral
                        title="Participação por quantidade de vendas"
                    >
                        {/* moreInfo="Participação acumulada de receita por tipo de produto comercializado" */}
                        {!loading ?
                            <Chart
                                options={
                                    {
                                        ...vendaPorRegiaoQuantidade?.options,
                                        chart: {
                                            events: {
                                                dataPointSelection: showClick
                                            }
                                        }
                                    }
                                }
                                series={vendaPorRegiaoQuantidade?.series}
                                type='pie'
                                height='200px'
                            /> : <div className='loading-item__container'>
                                <img className='loading-item' src={Loading} alt='Loading' />
                            </div>
                        }
                    </CardGeneral>
                </div>
                {/* PARETO */}
                <div className='col-12'>
                    <CardGeneral
                        title="Análise Pareto"
                        moreInfo={explanationChart.analisePareto}
                    >
                        <div className="d-flex">
                            <div className="flex-1">
                                {paretoChartOptions && !loading ?
                                    <Chart
                                        options={themeReducer === 'theme-mode-dark' ? {
                                            ...paretoChartOptions.options,
                                            theme: { mode: 'dark' }
                                        } : {
                                            ...paretoChartOptions.options,
                                            theme: { mode: 'light' }
                                        }}
                                        series={paretoChartOptions.series}
                                        type='bar'
                                        height='100%'
                                    /> : <div className='loading-item__container'>
                                        <img className='loading-item' src={Loading} alt='Loading' /></div>
                                }
                            </div>
                            <div className="flex-1 analise-content">
                                <h6 className="analise-title">
                                    Análise
                                </h6>
                                {
                                    paretoAnalises.map((item, index) => (
                                        <p className="analise-item" key={index}>{item}</p>
                                    ))
                                }
                            </div>
                        </div>
                    </CardGeneral>
                </div>
                {/* QUARTIS */}
                <div className='col-12'>
                    <CardGeneral
                        title="análise de quartis"
                        moreInfo={explanationChart.analiseQuartil}
                    >
                        <div className='row stats'>
                            {dashboardReducer?.data?.analise_quartil?.vendas_por_quartil &&
                                dashboardReducer?.data?.analise_quartil?.vendas_por_quartil.map((item, index) => (
                                    <div className="col-3" key={index}>
                                        <StatusCard
                                            icon=""
                                            count={formatCurrency(item)}
                                            title={`${index + 1}º Quartil`}
                                        />
                                    </div>
                                ))
                            }
                        </div>
                        <div className="d-flex chart">
                            <div className="flex-1">
                                {percentileChartOptions && !loading ?
                                    <Chart
                                        options={themeReducer === 'theme-mode-dark' ? {
                                            ...percentileChartOptions.options,
                                            theme: { mode: 'dark' }
                                        } : {
                                            ...percentileChartOptions.options,
                                            theme: { mode: 'light' }
                                        }}
                                        series={percentileChartOptions.series}
                                        type='bar'
                                        height='100%'
                                    /> : <div className='loading-item__container'>
                                        <img className='loading-item' src={Loading} alt='Loading' /></div>
                                }
                            </div>
                            <div className="flex-1 analise-content">
                                <h6 className="analise-title">
                                    Análise
                                </h6>
                                {
                                    quartilAnalises.map((item, index) => (
                                        <p className="analise-item" key={index}>{item}</p>
                                    ))
                                }
                            </div>
                        </div>
                    </CardGeneral>
                </div>
                {/* TABELA DE CLIENTES */}
                <div className='col-12'>
                    <CardGeneral title="Tabela de clientes">
                        {/* rowSelection={{...rowSelection}}  */}
                        <Table
                            dataSource={customerList}
                            columns={columns}
                            onRow={(record) => {
                                return {
                                    onClick: () => {
                                        console.log(record)
                                        setClientSelected(record)
                                        showModal()
                                    }
                                };
                            }}
                        />
                        <Modal title={titleCase(clientSelected?.nomeCliente) || ''}
                            visible={isModalVisible}
                            onOk={handleOk}
                            okText={'Fechar'}
                            closable={false}
                            cancelButtonProps={{ style: { display: 'none' } }}
                        >
                            {!!clientSelected &&
                                <Chart
                                    options={chartPieClientSelected.options}
                                    series={chartPieClientSelected.series}
                                    type='pie'
                                    height='250px'
                                />
                            }
                        </Modal>
                    </CardGeneral>
                </div>
            </div>
            {/* MODAL */}
            <Modal title={`Produtos de ${regiaoProdutoSelected}`}
                subti
                visible={isModalOpen}
                onOk={closeModalRegiao}
                onCancel={closeModalRegiao}
                okText={'Fechar'}
                cancelButtonProps={{ style: { display: 'none' } }}
                width={780}
            >
                <Table
                    dataSource={produtosRegiao && produtosRegiao[regiaoProdutoSelected]}
                    columns={columnsVendaRegiao}
                />
            </Modal>
        </CardGeneral>
    )

}

export default AnaliseClienteDashboard