import React, { useEffect, useState } from 'react'
import moment from 'moment';
import {
    chartOptionsData,
    paretoChartOptionsData,
    percentileChartOptionsData,
    produtoChartOptionsData,
    produtoTipoChartOptionsData,
    chartTreeMap,
    vendaRegiaoChartOptionsData,
    vendaRegiaoQuantidadeChartOptionsData
} from '../../utils/chart-utils'

import { formatCurrency } from '../../utils/format'

import { useSelector } from 'react-redux'

import { getUserClients, getUserDashboards, getRegions, getProducts, getParts } from '../../services/dashboard.service'
import DashboardAction from '../../store/actions/DashboardAction'
import ImportButton from '../../components/ImportButton'
import Loading from '../../assets/images/loading.svg'
import { Button, Card, DatePicker, Form, message, Modal, Select, Space, Tag, Tooltip } from 'antd'

import './styles.css'
import DeleteButton from '../../components/DeleteButton'
import explanationChart from "../../services/JsonData/explanationChart.json"
import TotalizadosDashboard from '../../components/TotalizadosDashboard'
import AnaliseProdutoDashboard from '../../components/AnaliseProdutoDashboard'
import AnaliseClienteDashboard from '../../components/AnaliseClienteDashboard'
import { FilterOutlined, CloseOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker
const { Option } = Select;

const titleCase = text => {
	return text.toLowerCase()
		.split(' ')
		.map((word) => {
			return word[0]?.toUpperCase() + word.slice(1);
		}).join(' ')
}

const Dashboard = (props) => {

    const themeReducer = useSelector(state => state.ThemeReducer.mode)
    const [loading, setLoading] = useState(true)
    const [dashboard, setDashboard] = useState(props.dashboard)
    const [produtoChartOptions, setProdutoChartOptions] = useState(null)
    const [chartOptions, setChartOptions] = useState(null)
    const [produtoTipoChartOptions, setProdutoTipoChartOptions] = useState(null)
    const [paretoChartOptions, setParetoChartOptions] = useState(null)
    const [paretoAnalises, setParetoAnalises] = useState([])
    const [dataTreeMap, setDataTreeMap] = useState([])
    const [percentileChartOptions, setPercentileChartOptions] = useState(null)
    const [quartilAnalises, setQuartilAnalises] = useState([])
    const [isImportButtonOpen, setIsImportButtonOpen] = useState(false)
    const [isDeleteButtonOpen, setIsDeleteButtonOpen] = useState(false)
    const [customerList, setCustomerList] = useState([])
    const [dateStart, setDateStart] = useState('');
    const [dateEnd, setDateEnd] = useState('');
    const [customerListChart, setCustomerListChart] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalFilterVisible, setIsModalFilterVisible] = useState(false)
    const [typeProductFilter, setTypeProductFilter] = useState(null)
    const [typePartFilter, setTypePartFilter] = useState(null)
    const [typeMachineEquipmentFilter, setTypeMachineEquipmentFilter] = useState(null)
    const [quartilFilter, setQuartilFilter] = useState(null)
    const [regionFilter, setRegionFilter] = useState(null)
    const [vendaPorRegiao, setVendaPorRegiao] = useState(null)
    const [vendaPorRegiaoQuantidade, setVendaPorRegiaoQuantidade] = useState(null)
    const [typeAbcProduct, setTypeAbcProduct] = useState("")
    const [produtosRegiao, setProdutosRegiao] = useState(null)
    const [regionOption, setRegionOption] = useState([])
    const [produtoOption, setProdutoOption] = useState([])
    const [partsOption, setPartsOption] = useState([])

    useEffect(() => {
        async function getItems() {
            const regionOptionResult = await getRegions()
            const productOptionResult = await getProducts()
            const partsOptionResult = await getParts()

            setRegionOption([
                { label: 'Todas', value: null },
                ...regionOptionResult.map(item => (
                    {
                        label: titleCase(item),
                        value: item
                    }
                    ))
                ])
            setProdutoOption([
                { label: 'Todos', value: null },
                ...productOptionResult.map(item => (
                    {
                        label: item,
                        value: item
                    }
                ))
            ])
            setPartsOption([
                { label: 'Todas', value: null },
                ...partsOptionResult.map(item => (
                    {
                        label: item,
                        value: item
                    }
                ))
            ])
        }

        getItems()
    }, [])

    useEffect(() => {
        generateCharts();
        getClientes();
    }, [dashboard])

    const openImportButton = () => {
        setIsImportButtonOpen(true)
    }

    const closeImportButton = () => {
        setIsImportButtonOpen(false)
    }

    const openDeleteButton = () => {
        setIsDeleteButtonOpen(true)
    }

    const closeDeleteButton = () => {
        setIsDeleteButtonOpen(false)
    }

    const applyFilters = async () => {
        try {

            setLoading(true)
            const revertAndFormatData = value => value.split('/').reverse().join('-')

            const filter = {}
            if(quartilFilter) filter.quartil = quartilFilter
            if(regionFilter) filter.regiao = regionFilter
            if(typeProductFilter) filter.tipoProduto = typeProductFilter
            if(typeProductFilter && (typePartFilter || typeMachineEquipmentFilter)) {
                filter.subtipo = !!typeProductFilter === 'PECAS' ? typePartFilter : typeMachineEquipmentFilter
            }
            if (!!dateStart) filter.data_inicial = revertAndFormatData(dateStart);
            if (!!dateEnd) filter.data_final = revertAndFormatData(dateEnd);

            getUserDashboards(filter)
                .then(res => {
                    setDashboard(res)
                    setLoading(false)
                })
                .catch((error) => {
                    setDashboard(props.dashboard)
                    console.error(error)
                    message.error('Erro ao carregar os dados de dashboard')
                })
                .then(async () => {
                    console.log("clients")
                    await getUserClients(filter).then(res => {
                        const list = !!res.lista_clientes ? res.lista_clientes.map((row, index) => ({ ...row, idIndex: index + 1, Valor_Venda: formatCurrency(row.Valor_Venda), Data_Ultima_Compra: moment(row.Data_Ultima_Compra).format('DD/MM/YYYY  hh:mm:ss') })) : []
                        setCustomerList(list)
                        setCustomerListChart(res?.produtos_cliente)
                    }).catch(() => {
                        message.error('Erro ao carregar os dados de clientes')
                    })
                })
        } catch (error) {
            console.log(error)
        }   
    }

    function generateCharts() {
        setLoading(true)
        Promise.all([
            setChartOptions(chartOptionsData(dashboard.data)),
            setParetoChartOptions(paretoChartOptionsData(dashboard.data)),
            setPercentileChartOptions(percentileChartOptionsData(dashboard.data)),
            setParetoAnalises(dashboard.data.analise_grafico_pareto),
            setQuartilAnalises(dashboard.data.analise_grafico_quartil),
            setProdutoChartOptions(produtoChartOptionsData(dashboard)),
            setProdutoTipoChartOptions(produtoTipoChartOptionsData(dashboard)),
            setDataTreeMap(chartTreeMap({ ...dashboard, functionClick: setTypeAbcProduct })),
            setDateStart(moment(dashboard.data?.data_inicio).format('DD/MM/YYYY')),
            setDateEnd(moment(dashboard.data?.data_fim).format('DD/MM/YYYY')),
            setVendaPorRegiao(vendaRegiaoChartOptionsData(dashboard)),
            setVendaPorRegiaoQuantidade(vendaRegiaoQuantidadeChartOptionsData(dashboard)),
            setProdutosRegiao(dashboard.data.produtos_regiao)
        ]).finally(() => {
            setLoading(false)
        });
    }

    function getClientes() {
        getUserClients({ data_inicial: dateStart, data_final: dateEnd }).then(res => {
            const list = !!res.lista_clientes ? res.lista_clientes.map((row, index) => ({ ...row, idIndex: index + 1, Valor_Venda: formatCurrency(row.Valor_Venda), Data_Ultima_Compra: moment(row.Data_Ultima_Compra).format('DD/MM/YYYY  hh:mm:ss') })) : []
            console.warn(list)
            setCustomerList(list)
            setCustomerListChart(res?.produtos_cliente)
        }).catch(() => {
            message.error('Erro ao carregar os dados de clientes')
        })
    }

    function onChangeRangePicker(value, dateString) {
        setDateStart(dateString[0])
        setDateEnd(dateString[1])
        applyFilters()
    }

    function hasFilter() {
        return !!typeProductFilter || !!typePartFilter || !!typeMachineEquipmentFilter || !!quartilFilter || !!regionFilter
    }

    useEffect(() => {
        applyFilters()
    }, [quartilFilter, regionFilter, typeProductFilter, typePartFilter, typeMachineEquipmentFilter])

    return (
        <div>
            <div className="dashboard__header">
                <h2 className="page-header">Insight Sales</h2>
                <div className="button__container">
                    <div className='filters__container'>
                        <Tooltip title="Filtrar">
                            <Button onClick={() => setIsModalFilterVisible(true)} style={{ borderColor: '#CE1459', background: '#CE1459' }} size="large" type="primary" shape="circle" icon={<FilterOutlined />} />
                        </Tooltip>
                        <Modal title="Filtros" cancelButtonProps={{ style: { display: 'none' } }} visible={isModalFilterVisible} okText="Fechar" onOk={() => setIsModalFilterVisible(false)} onCancel={() => setIsModalFilterVisible(false)}>
                            <Form layout="vertical">
                                <Form.Item label="Tipo de produto">
                                    <Select
                                        defaultValue={null}
                                        value={typeProductFilter}
                                        style={{ width: '100%' }}
                                        onChange={(value) => setTypeProductFilter(value)}
                                    >
                                        <Option value={null}>
                                            Todos
                                        </Option>
                                        <Option value="MAQUINAS">
                                            Máquina/Equipamento
                                        </Option>
                                        <Option value="PECAS">
                                            Peça
                                        </Option>
                                    </Select>
                                </Form.Item>
                                {
                                    typeProductFilter === 'MAQUINAS' && <Form.Item label="Tipo de máquina/equipamento">
                                        <Select
                                            defaultValue={null}
                                            value={typeMachineEquipmentFilter}
                                            style={{ width: '100%' }}
                                            onChange={(value) => setTypeMachineEquipmentFilter(value)}
                                        >
                                            {
                                                produtoOption.map(({label, value}) => (
                                                    <Option value={value}>{label}</Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                }
                                {
                                    typeProductFilter === 'PECAS' && <Form.Item label="Tipo de peça">
                                        <Select
                                            defaultValue={null}
                                            value={typePartFilter}
                                            style={{ width: '100%' }}
                                            onChange={(value) => setTypePartFilter(value)}
                                        >
                                            {
                                                partsOption.map(({label, value}) => (
                                                    <Option value={value}>{label}</Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                }
                                
                                
                                <Form.Item label="Quartil​">
                                    <Select
                                        defaultValue={null}
                                        value={quartilFilter}
                                        style={{ width: '100%' }}
                                        onChange={(value) => setQuartilFilter(value)}
                                    >
                                        <Option value={null}>
                                            Todos
                                        </Option>
                                        <Option value="1">
                                            1º Quartil
                                        </Option>
                                        <Option value="2">
                                            2º Quartil
                                        </Option>
                                        <Option value="3">
                                            3º Quartil
                                        </Option>
                                        <Option value="4">
                                            4º Quartil
                                        </Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Região">
                                    <Select
                                        defaultValue={null}
                                        value={regionFilter}
                                        style={{ width: '100%' }}
                                        onChange={(value) => setRegionFilter(value)}
                                    >
                                        {
                                            regionOption.map(({label, value}) => (
                                                <Option value={value}>{label}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Form>
                        </Modal>
                        {!!dashboard.data?.data_inicio && !!dashboard.data?.data_fim &&
                            <RangePicker
                                defaultValue={[moment(moment(dashboard.data?.data_inicio).format('DD/MM/YYYY'), 'DD/MM/YYYY'), moment(moment(dashboard.data?.data_fim).format('DD/MM/YYYY'), 'DD/MM/YYYY')]}
                                format="DD/MM/YYYY"
                                onChange={onChangeRangePicker}
                            />
                        }
                    </div>
                    <ImportButton className='import__container' buttonTtitle={'Importar dados'}
                        onImportButtonClose={closeImportButton}
                        onImportButtonOpen={openImportButton}
                        modalState={isImportButtonOpen}
                        update
                        dashboardId={dashboard?.id}
                    />
                    <DeleteButton className='delete__container'
                        onDeleteButtonClose={closeDeleteButton}
                        onDeleteButtonOpen={openDeleteButton}
                        modalState={isDeleteButtonOpen}
                        dashboardId={dashboard?.id}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    {
                        hasFilter() &&
                        <Card size="small" title="Filtros" style={{ width: '100%' }}>
                            <Space wrap>
                                {
                                    !!typeProductFilter && <Tag color="processing">
                                        <span>Tipo de Produto:</span> <b>{typeProductFilter}</b>
                                        <Tooltip title="Remover">
                                            <Button onClick={() => setTypeProductFilter(null)} size="small" type="danger" shape="circle" icon={<CloseOutlined />} />
                                        </Tooltip>
                                    </Tag>
                                }
                                {
                                    !!typePartFilter && <Tag color="processing">
                                        <span>Tipo de Peça:</span> <b>{typePartFilter}</b>
                                        <Tooltip title="Remover">
                                            <Button onClick={() => setTypePartFilter(null)} size="small" type="danger" shape="circle" icon={<CloseOutlined />} />
                                        </Tooltip>
                                    </Tag>
                                }
                                {
                                    !!typeMachineEquipmentFilter && <Tag color="processing">
                                        <span>Máquina/Equipamento:</span> <b>{typeMachineEquipmentFilter}</b>
                                        <Tooltip title="Remover">
                                            <Button onClick={() => setTypeMachineEquipmentFilter(null)} size="small" type="danger" shape="circle" icon={<CloseOutlined />} />
                                        </Tooltip>
                                    </Tag>
                                }
                                {
                                    !!quartilFilter && <Tag color="processing">
                                        <span>Quartil: </span> <b>{quartilFilter}</b>
                                        <Tooltip title="Remover">
                                            <Button onClick={() => setQuartilFilter(null)} size="small" type="danger" shape="circle" icon={<CloseOutlined />} />
                                        </Tooltip>
                                    </Tag>
                                }
                                {
                                    !!regionFilter && <Tag color="processing">
                                        <span>Região: </span> <b>{regionFilter}</b>
                                        <Tooltip title="Remover">
                                            <Button onClick={() => setRegionFilter(null)} size="small" type="danger" shape="circle" icon={<CloseOutlined />} />
                                        </Tooltip>
                                    </Tag>
                                }
                            </Space>
                        </Card>
                    }
                </div>
            </div>
            <br />
            <div className="row">
                <div className="col-12">

                    <TotalizadosDashboard
                        setIsModalVisible={setIsModalVisible}
                        data={dashboard.data}
                    />

                    <AnaliseProdutoDashboard
                        Loading={Loading}
                        chartOptions={chartOptions}
                        explanationChart={explanationChart}
                        loading={loading}
                        produtoChartOptions={produtoChartOptions}
                        themeReducer={themeReducer}
                        produtoTipoChartOptions={produtoTipoChartOptions}
                        dataTreeMap={dataTreeMap}
                        typeAbcProduct={typeAbcProduct}
                    />

                    <AnaliseClienteDashboard
                        explanationChart={explanationChart}
                        paretoChartOptions={paretoChartOptions}
                        loading={loading}
                        themeReducer={themeReducer}
                        Loading={Loading}
                        paretoAnalises={paretoAnalises}
                        dashboardReducer={dashboard}
                        percentileChartOptions={percentileChartOptions}
                        quartilAnalises={quartilAnalises}
                        customerList={customerList}
                        customerListChart={customerListChart}
                        produtoTipoChartOptions={produtoTipoChartOptions}
                        vendaPorRegiao={vendaPorRegiao}
                        vendaPorRegiaoQuantidade={vendaPorRegiaoQuantidade}
                        produtosRegiao={produtosRegiao}
                    />
                </div>
            </div>
        </div>
    )
}

export default Dashboard
