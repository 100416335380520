import { createActionPlan } from '../../services/actionPlan.service'
import { Modal, message, Button } from 'antd'
import { useState } from 'react';
import StepByStepStockStrategy from '../../components/StepByStepStockStrategy';

const ModalStockStrategy = ({ textButton, afterSaveAction, style }) => {

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [current, setCurrent] = useState(0);
    const [nome, setNome] = useState("")
    const [plano, setPlano] = useState("")
    const [pecaOrEquip, setPecaOrEquip] = useState(null)
    const [quartil, setQuartil] = useState(['todos'])
    const [regiao, setRegiao] = useState(null)
    const [segmento, setSegmento] = useState(null)      
    const [preview, setPreview] = useState(null)
    const [formValidated, setFormValidated] = useState(false)
    const [salesConversionGoal, setSalesConversionGoal] = useState(20)
    const [produto, setProduto] = useState(null)
    const [tempoUltimaCompra, setTempoUltimaCompra] = useState("3")

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const reset = () => {
        setNome("")
        setPlano("")
        setQuartil(['todos'])
        setRegiao(null)
        setSegmento(null)
        setPreview(null)
        setFormValidated(false)
        setSalesConversionGoal(20)
        setProduto("")
        setPecaOrEquip("PECA")
        setTempoUltimaCompra("")
    }

    const handleCancel = () => {
        setIsModalVisible(false);
        setCurrent(0)
        reset();
    };

    const createPlan = async () => {
        const stockStrategy = {
            plano,
            nome,
            data: {
                ...preview,
                salesConversionGoal,
                quartil: quartil.includes('todos') ? [1,2,3,4] : quartil,
                regiao,
                segmento
            }
        }

        await createActionPlan(stockStrategy).then(
            res => {
                message.success('Estratégia salva com sucesso!')
                handleOk()
                setCurrent(0)
                if (!!afterSaveAction) afterSaveAction()
            })
            .catch(err => { console.error(err) })
            .finally(() => {
            })
    }

    return (
        <>
            <Button type="primary" style={style} onClick={showModal}>{textButton}</Button>

            <Modal
                title="Adicionar estratégia de ação"
                visible={isModalVisible}
                okButtonProps={{ danger: true }}
                width={1000}
                okText="Adicionar"
                footer={null}
                onOk={handleOk}
                onCancel={handleCancel}
                afterClose={reset}
            >

                <StepByStepStockStrategy
                    plano={plano}
                    onSelectPlano={setPlano}
                    formValidated={formValidated}
                    setFormValidated={setFormValidated}
                    nome={nome}
                    onInputName={setNome}
                    pecaOrEquip={pecaOrEquip}
                    onSelectPecaOrEquip={setPecaOrEquip}
                    produto={produto}
                    onSelectProduto={setProduto}
                    quartil={quartil}
                    onSelectQuartil={setQuartil}
                    regiao={regiao}
                    onSelectRegiao={setRegiao}
                    segmento={segmento}
                    onSelectSegmento={setSegmento}
                    tempoUltimaCompra={tempoUltimaCompra}
                    onSelectTempoUltimaCompra={setTempoUltimaCompra}
                    preview={preview}
                    setPreview={setPreview}
                    salesConversionGoal={salesConversionGoal}
                    onChangeSalesConversionGoal={setSalesConversionGoal}
                    current={current}
                    setCurrent={setCurrent}
                    createPlan={createPlan}
                />

            </Modal>

        </>
    )
}

export default ModalStockStrategy
