import { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import "./styles.css";
import { Button, Col, Form, Modal, Row, Select, Table, Tooltip, Card, Statistic, DatePicker, Spin } from "antd";
import StatusCard from "../../components/StatusCard";
import { DownloadOutlined, FilterOutlined, CaretUpOutlined, CaretDownOutlined, BoxPlotOutlined } from '@ant-design/icons';
import Chart from 'react-apexcharts'
import CardGeneral from "../../components/CardGeneral";
import ModalStockStrategy from "../../components/ModalStockStrategy";
import Search from "antd/lib/input/Search";
import { getPartsModule } from '../../services/partsModule.service'
import { formatCurrency, formatPercent, formatDate, formatNumber } from "../../utils/format"
import { months } from "../../utils/chart-utils"

const { RangePicker } = DatePicker
const { Option } = Select;

const generateChartPie = (series = [], labels = [] ) => ({
    series,
    options: {
        chart: {
            width: 380,
            type: 'donut',
        },
        yaxis: {
            labels: {
                formatter: function (value) {
                    return formatPercent(value);
                }
            },
        },
        labels,
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }],
        legend: {
            position: 'bottom',
            offsetX: 40
        }
    },
})

const generateChartBar = (series = [], categories = []) => ({
    series,
    options: {
        chart: {
            type: 'bar',
            height: 350
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded',
                dataLabels: {
                    position: 'top', // top, center, bottom
                },
            },
        },
        dataLabels: {
            enabled: false,
            formatter: function (val, index) {
                return formatCurrency(val);
            },
            offsetY: -20,
            style: {
              fontSize: '12px',
              colors: ["#304758"]
            }
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories,
        },
        yaxis: {
            // title: {
            //     text: '(Milhares de Reais)'
            // },
            labels: {
                formatter: function (val, index) {
                  return formatCurrency(val);
                }
            }
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: formatCurrency
            }
        }
    },
})

const generateChartBarWithLine = (series = [], categories = []) => ({
    series,
    options: {
        chart: {
            height: 500,
            type: 'line',
            stacked: false
        },
        dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
        },
        dataLabels: {
            enabled: true,
            formatter: function (val, index) {
                return formatPercent(val);
            },
            offsetY: -20,
            style: {
              fontSize: '12px',
              colors: ["#304758"]
            }
        },
        stroke: {
            // width: [1, 1, 4],
            curve: 'smooth'
        },
        xaxis: {
            categories,
        },
        yaxis: [
        //     {
        //         seriesName: 'Total Realizado',
        //         axisTicks: {
        //             show: true,
        //         },
        //         axisBorder: {
        //             show: true,
        //             color: '#008FFB'
        //         },
        //         labels: {
        //             formatter: function (val, index) {
        //                 return formatCurrency(val);
        //             },
        //             style: {
        //                 colors: '#008FFB',
        //             }
        //         },
        //         title: {
        //             text: "Total Realizado",
        //             style: {
        //                 color: '#008FFB',
        //             }
        //         },
        //         tooltip: {
        //             enabled: true
        //         }
        //     },
        //     {
        //         seriesName: 'Potencial',
        //         opposite: true,
        //         axisTicks: {
        //             show: true,
        //         },
        //         axisBorder: {
        //             show: true,
        //             color: '#00E396'
        //         },
        //         labels: {
        //             formatter: function (val, index) {
        //                 return formatCurrency(val);
        //             },
        //             style: {
        //                 colors: '#00E396',
        //             }
        //         },
        //         title: {
        //             text: "Potencial",
        //             style: {
        //                 color: '#00E396',
        //             }
        //         },
        //     },
        {
                seriesName: 'Market Share',
                // opposite: true,
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: '#FEB019'
                },
                labels: {
                    formatter: function (val, index) {
                        return formatPercent(val);
                    },
                    style: {
                        colors: '#FEB019',
                    },
                },
                title: {
                    text: "Market Share",
                    style: {
                        color: '#FEB019',
                    }
                }
            },
        ],
        grid: {
            borderColor: '#e7e7e7',
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
        },
        markers: {
            size: 1
        },
        tooltip: {
            fixed: {
                enabled: true,
                // position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
                // offsetY: 30,
                // offsetX: 60
            },
        },
        legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            offsetX: 40
        }
    }
})

const titleCase = text => {
	return text.toLowerCase()
		.split(' ')
		.map((word) => {
			return word[0]?.toUpperCase() + word.slice(1);
		}).join(' ')
}

const Inicio = () => {
    const [loading, setLoading] = useState(true)

    const [data, setData] = useState([])
    const [dataFiltered, setDataFiltered] = useState([])
    const [isModalFilterVisible, setIsModalFilterVisible] = useState(false)
    const [quartilFilter, setQuartilFilter] = useState(null)
    const [regionFilter, setRegionFilter] = useState(null)
    const [isModalClienteVisible, setIsModalClienteVisible] = useState(false)
    const [showModalDetails, setShowModalDetails] = useState(false)
    const [dataDetailsSelected, setDataDetailsSelected] = useState({})
    const [dataPartsModule, setDataPartsModule] = useState({})
    const [chartPie, setChartPie] = useState(generateChartPie())
    const [chartPieMarketShare, setChartPieMarketShare] = useState(generateChartPie())
    const [chartBar, setChartBar] = useState(generateChartBar())
    const [chartBarMarketShare, setChartBarMarketShare] = useState(generateChartBar())
    const [chartBarMarketShareLine, setChartBarMarketShareLine] = useState(generateChartBarWithLine())
    const [dateStart, setDateStart] = useState();
    const [dateEnd, setDateEnd] = useState();

    const columns = [
        {
            title: 'Nome',
            dataIndex: 'nomeCliente',
            key: 'nomeCliente',
            render: (value) => titleCase(value)
        },
        {
            title: 'Última Compra',
            dataIndex: 'dataVenda',
            key: 'dataVenda',
            render: (value) => formatDate(value)
        },
        {
            title: 'Segmento',
            dataIndex: 'segmentoCliente',
            key: 'segmentoCliente',
            render: (value) => value || ' - '
        },
        {
            title: 'Preventivo',
            dataIndex: 'potencialPreventivo',
            key: 'potencialPreventivo',
            render: (value) => formatCurrency(value)
        },
        {
            title: 'Corretivo',
            dataIndex: 'potencial_corretivo',
            key: 'potencial_corretivo',
            render: (value) => formatCurrency(value)
        },
        {
            title: 'Total',
            dataIndex: 'potencialTotal',
            key: 'potencialTotal',
            render: (value) => formatCurrency(value)
        },
        {
            title: 'Informações',
            key: 'informacoes',
            render: (value) => (
                <Button type="link" onClick={
                    () => {
                        setShowModalDetails(true)
                        setDataDetailsSelected(value)
                        console.log(value)
                    }
                }>
                    Detalhes
                </Button>
            )
        },
    ];

    const expandedRowRender = (record) => {
        const columnsParts = [
            {
                title: 'Descrição',
                dataIndex: 'DESCRIÇÃO',
                key: 'DESCRIÇÃO',
                render: (text) => <div>{text ? text : '-'}</div>
            },
            {
                title: 'Subdescrição',
                dataIndex: 'SUB DESCRIÇÃO',
                key: 'SUB DESCRIÇÃO',
                render: (text) => <div>{text ? text : '-'}</div>
            },
            {
                title: 'Modelo',
                dataIndex: 'MODELO',
                key: 'MODELO',
                render: (text) => <div>{text ? text : '-'}</div>
            },
            {
                title: 'Valor',
                dataIndex: 'VALOR TOTAL SLP BRASIL',
                key: 'VALOR TOTAL SLP BRASIL',
                render: (text) => <div>{text ? formatCurrency(Number(text)) : '-'}</div>
            },
            {
                title: 'Horímetro Total Estimado',
                dataIndex: 'horimetroTotalEstimado',
                key: 'horimetroTotalEstimado',
                render: (text) => <div>{text ? formatNumber(Number(text)) : '-'}</div>
            },
            {
                title: 'Próxima Revisão',
                dataIndex: 'proximaRevisao',
                key: 'proximaRevisao',
                render: (text) => <div>{text ? formatNumber(Number(text)) : '-'}</div>
            },
        ];

        return <Table columns={columnsParts} dataSource={record.parts} pagination={false} />
    }

    const columnsDetails = [
        {
            title: 'Máquina',
            dataIndex: 'machine',
            key: 'machine',
        },
        {
            title: 'Data Venda',
            dataIndex: 'dataVenda',
            key: 'dataVenda',
            render: (value) => formatDate(value)
        },
        {
            title: 'Potencial Total',
            dataIndex: 'potencial_pecas_mensal',
            key: 'potencial_pecas_mensal',
            render: formatCurrency
        }
    ]

    const search = (value) => {
        const teste = data.filter((item) => {
            return item.nomeCliente.toLowerCase().includes(value?.toLowerCase())
        })
        setDataFiltered(teste)
    }

    const getData = (data_inicial = '', data_final = '') => {
        setLoading(true)
        const filter = {}
        if (data_inicial) filter.data_inicial = data_inicial
        if (data_final) filter.data_final = data_final
        if (regionFilter) filter.regiao = regionFilter
        if (quartilFilter) filter.quartil = quartilFilter
        getPartsModule(filter)
            .then((result) => {
                setDataPartsModule(result.data)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {setLoading(false)})
    }

    const generateMachines = (value, parts = {}) => {
        if(!value) return []
        const nameMachines = Object.keys(value)
        const dataMachines = Object.values(value)
        const newObj = {}
        const newParts = {}

        nameMachines.map((machine) => {
            newObj[`${machine}`] = value[machine]
        })

        Object.keys(parts).map((key) => {
            newParts[`${key}`] = parts[key]
        })

        const resultado = Object.keys(newObj).map((machine, index) => {
            console.log("GENERATE", machine, dataMachines[index]);
            return {
                machine: `${dataMachines[index]['Model #']} (${machine.trim()})`,
                ...dataMachines[index],
                parts: !!newParts[machine] ? newParts[machine] : []
            }
        })

        console.log("Resultado", resultado)

        return  resultado
    }

    const revertAndFormatData = value => {
        try {
            return value.split('/').reverse().join('-')
        } catch {
            return ''
        }
    }

    async function onChangeRangePicker(value, dateString) {
        setDateStart(dateString[0])
        setDateEnd(dateString[1])
        getData(revertAndFormatData(dateString[0]), revertAndFormatData(dateString[1]))
    }

    function downloadData() {
        const titleKeys = Object.keys(dataFiltered[0])

        const refinedData = []
        refinedData.push(titleKeys)

        dataFiltered.forEach(item => {
            refinedData.push(Object.values(item))  
        })

        let csvContent = ''

        refinedData.forEach(row => {
            csvContent += row.join(',') + '\n'
        })

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8,' })
        const objUrl = URL.createObjectURL(blob)

        const link = document.createElement('a')
        link.setAttribute('href', objUrl)
        link.setAttribute('download', 'clientes.csv')
        link.click();
        window.URL.revokeObjectURL(objUrl);
        link.remove();
    }

    useEffect(() => {
        // search('')
        getData()
    }, [])

    useEffect(() => {
        if (!!dataPartsModule && Object.keys(dataPartsModule).length > 0) {
            setChartPie(
                generateChartPie(
                    Object.values(dataPartsModule.participacao_dealer),
                    Object.keys(dataPartsModule.participacao_dealer)
                )
            )
            setChartPieMarketShare(
                generateChartPie(
                    Object.values(dataPartsModule.participacao_segmento),
                    Object.keys(dataPartsModule.participacao_segmento)
                )
            )
            setChartBar(
                generateChartBar(
                    [
                        {
                            name: 'Total',
                            data: Object.values(dataPartsModule.grafico_potencial_vendas_mes)
                        }
                    ],
                    Object.keys(dataPartsModule.grafico_potencial_vendas_mes).map(m => months[m])
                )
            )
            setChartBarMarketShare(
                generateChartBar(
                    [
                        {
                            name: 'Total Realizado',
                            type: 'column',
                            data: Object.values(dataPartsModule.performance_marketshare).map(m => m.total_realizado),
                        },
                        {
                            name: 'Potencial Vendas',
                            type: 'column',
                            data: Object.values(dataPartsModule.performance_marketshare).map(m => m.potencial_vendas),
                        }
                    ],
                    Object.keys(dataPartsModule.performance_marketshare).map(m => months[m])
                )
            )
            setChartBarMarketShareLine(
                generateChartBarWithLine(
                    [
                        {
                            name: 'Market Share',
                            type: 'line',
                            data: Object.values(dataPartsModule.performance_marketshare).map(m => (m.markestshare/100)),
                        }
                    ],
                    Object.keys(dataPartsModule.performance_marketshare).map(m => months[m])
                )
            )
            setData(dataPartsModule.clientes_potencial.map(
                (value) => (
                    {
                        ...value,
                        potencial_preventivo: value.potencial_pecas_mensal - value.potencial_corretivo,
                        machines: generateMachines(
                            dataPartsModule.info_clientes[value.nomeCliente],
                            dataPartsModule.maquinas_preventivo_clientes[value.nomeCliente],
                        )
                    }
                )
            ))
            setDataFiltered(dataPartsModule.clientes_potencial.map(
                (value) => (
                    {
                        ...value,
                        potencial_preventivo: value.potencial_pecas_mensal - value.potencial_corretivo,
                        machines: generateMachines(
                            dataPartsModule.info_clientes[value.nomeCliente],
                            dataPartsModule.maquinas_preventivo_clientes[value.nomeCliente],
                        )
                    }
                )
            ))
        }
    }, [dataPartsModule])


    useEffect(() => {

        getData(revertAndFormatData(dateStart), revertAndFormatData(dateEnd))
    }, [quartilFilter, regionFilter])

    return (
        <Layout>
            <div style={{ display: 'flex' }}>
                <h2 style={{ marginBottom: '1rem' }}>Módulo de peças</h2>
                <div className='filters__container' style={{ marginLeft: '1rem' }}>
                    <Tooltip title="Filtrar">
                        <Button onClick={() => setIsModalFilterVisible(true)} style={{ borderColor: '#CE1459', background: '#CE1459' }} size="large" type="primary" shape="circle" icon={<FilterOutlined />} />
                    </Tooltip>
                    <Modal title="Filtros" cancelButtonProps={{ style: { display: 'none' } }} visible={isModalFilterVisible} okText="Fechar" onOk={() => setIsModalFilterVisible(false)} onCancel={() => setIsModalFilterVisible(false)}>
                        <Form layout="vertical">
                            <Form.Item label="Quartil​">
                                <Select
                                    defaultValue={null}
                                    value={quartilFilter}
                                    style={{ width: '100%' }}
                                    onChange={(value) => setQuartilFilter(value)}
                                >
                                    <Option value={null}>
                                        Todos
                                    </Option>
                                    <Option value="1">
                                        1º Quartil
                                    </Option>
                                    <Option value="2">
                                        2º Quartil
                                    </Option>
                                    <Option value="3">
                                        3º Quartil
                                    </Option>
                                    <Option value="4">
                                        4º Quartil
                                    </Option>
                                </Select>
                            </Form.Item>
                            <Form.Item label="Região">
                                <Select
                                    defaultValue={null}
                                    value={regionFilter}
                                    style={{ width: '100%' }}
                                    onChange={(value) => setRegionFilter(value)}
                                >
                                    <Option value={null}>
                                        Todas
                                    </Option>
                                    <Option value="NORTE">
                                        Norte
                                    </Option>
                                    <Option value="NORDESTE">
                                        Nordeste
                                    </Option>
                                </Select>
                            </Form.Item>
                        </Form>
                    </Modal>

                    <RangePicker
                        format="DD/MM/YYYY"
                        onChange={onChangeRangePicker}
                    />
                </div>
            </div>

            <Spin spinning={loading}>

                <Row gutter={16} style={{ marginBottom: '2rem' }}>
                    <Col className="gutter-row" span={24}>
                        <CardGeneral title="Totalizadores"
                            moreActions={
                                <StatusCard
                                    style={{ height: '36px' }}
                                    title={"População de Máquinas"}
                                    slotIcon={<BoxPlotOutlined size={9} style={{ marginRight: '1rem' }} />}
                                    styleBody={{ display: 'flex', flexDirection: 'row-reverse' }}
                                >
                                    <div style={{ marginLeft: '1rem', marginBottom: '-10px' }}>
                                        {dataPartsModule && dataPartsModule.total_maquinas}
                                    </div>
                                </StatusCard>
                            }
                        >
                            <Row gutter={16} style={{ marginBottom: '2rem' }}>
                                <Col className="gutter-row" span={6}>
                                    <StatusCard
                                        style={{ height: "100%" }}
                                        count={formatCurrency(dataPartsModule && dataPartsModule.potencial_vendas_ano && dataPartsModule.potencial_vendas_ano.total)}
                                        title={"Potencial de vendas Ano"}
                                    />
                                </Col>
                                <Col className="gutter-row" span={6}>
                                    <StatusCard
                                        style={{ height: "100%" }}
                                        count={formatCurrency(dataPartsModule && dataPartsModule.potencial_vendas_mes && dataPartsModule.potencial_vendas_mes.total)}
                                        title={"Potencial de Vendas Mês"}
                                    />
                                </Col>
                                <Col className="gutter-row" span={6}>
                                    <StatusCard
                                        style={{ height: "100%" }}
                                        count={formatCurrency(dataPartsModule && dataPartsModule.potencial_realizado && dataPartsModule.potencial_realizado.total)}
                                        title={"Total realizado"}
                                    />
                                </Col>
                                <Col className="gutter-row" span={6}>
                                    <StatusCard
                                        style={{ height: "100%" }}
                                        title={"Market Share"}
                                    >   
                                        {formatPercent(dataPartsModule && dataPartsModule.marketshare && (dataPartsModule.marketshare.total / 100))}
                                        {/* <Tooltip title="Anterior: 1%">
                                            <CaretUpOutlined style={{ color: 'green' }} />
                                        </Tooltip> */}
                                    </StatusCard>
                                </Col>
                            </Row>
                            <Row gutter={16} style={{ marginBottom: '2rem' }}>
                                <Col className="gutter-row" span={6}>
                                    <StatusCard
                                        style={{ height: "100%" }}
                                        count={formatCurrency(dataPartsModule && dataPartsModule.potencial_vendas_ano && dataPartsModule.potencial_vendas_ano.preventivo)}
                                        title={"Preventivo"}
                                        inner={
                                            <div>
                                                <h4 style={{ fontSize: "1.5rem", marginBottom: "10px", marginTop: "1rem" }}>
                                                    {formatCurrency(dataPartsModule && dataPartsModule.potencial_vendas_ano && dataPartsModule.potencial_vendas_ano.corretivo)}
                                                </h4>
                                                <span>Corretivo</span>
                                            </div>
                                        }
                                    />
                                </Col>
                                <Col className="gutter-row" span={6}>
                                    <StatusCard
                                        style={{ height: "100%" }}
                                        count={formatCurrency(dataPartsModule && dataPartsModule.potencial_vendas_mes && dataPartsModule.potencial_vendas_mes.preventivo)}
                                        title={"Preventivo"}
                                        inner={
                                            <div>
                                                <h4 style={{ fontSize: "1.5rem", marginBottom: "10px", marginTop: "1rem" }}>
                                                {formatCurrency(dataPartsModule && dataPartsModule.potencial_vendas_mes && dataPartsModule.potencial_vendas_mes.corretivo)}
                                                </h4>
                                                <span>Corretivo</span>
                                            </div>
                                        }
                                    />
                                </Col>
                                <Col className="gutter-row" span={6}>
                                    <StatusCard
                                        style={{ height: "100%" }}
                                        count={formatCurrency(dataPartsModule && dataPartsModule.potencial_vendas_mes && dataPartsModule.potencial_realizado.preventivo)}
                                        title={"Preventivo"}
                                        inner={
                                            <div>
                                                <h4 style={{ fontSize: "1.5rem", marginBottom: "10px", marginTop: "1rem" }}>
                                                {formatCurrency(dataPartsModule && dataPartsModule.potencial_vendas_mes && dataPartsModule.potencial_realizado.corretivo)}
                                                </h4>
                                                <span>Corretivo</span>
                                            </div>
                                        }
                                    />
                                </Col>
                                <Col className="gutter-row" span={6}>
                                    <StatusCard
                                        style={{ height: "100%" }}
                                        title={"Preventivo"}
                                        inner={
                                            <div>
                                                <h4 style={{ fontSize: "1.5rem", marginBottom: "10px", marginTop: "1rem" }}>
                                                    {formatPercent(dataPartsModule && dataPartsModule.marketshare && (dataPartsModule.marketshare.corretivo / 100))}
                                                    {/* <Tooltip title="Anterior: 1%">
                                                        <CaretUpOutlined style={{ color: 'green' }} />
                                                    </Tooltip> */}
                                                </h4>
                                                <span>Corretivo</span>
                                            </div>
                                        }
                                    >
                                        {formatPercent(dataPartsModule && dataPartsModule.marketshare && (dataPartsModule.marketshare.preventivo / 100))}
                                        {/* <Tooltip title="Anterior: 1%">
                                            <CaretDownOutlined style={{ color: 'red' }} />
                                        </Tooltip> */}
                                    </StatusCard>
                                </Col>
                            </Row>
                        </CardGeneral>
                    </Col>
                </Row>

                {/* TABELA DE CLIENTES */}
                <Row gutter={16}>
                    <Col className="gutter-row" span={24}>
                        <CardGeneral title="Clientes">
                            <Row gutter={16} style={{ marginBottom: '3rem' }}>
                                <Col className="gutter-row" span={6}>
                                    <StatusCard
                                        style={{ height: "100%" }}
                                        count={formatNumber(dataPartsModule.potencial_mes)}
                                        title={"Potencial Mês"}
                                        action={() => setIsModalClienteVisible(true)}
                                    />
                                    <Modal width={1000} title="Clientes em Potencial do Mês" cancelButtonProps={{ style: { display: 'none' } }} visible={isModalClienteVisible} okText="Fechar" onOk={() => setIsModalClienteVisible(false)} onCancel={() => setIsModalClienteVisible(false)}>
                                        <Table columns={columns} dataSource={dataFiltered} />
                                    </Modal>
                                </Col>
                                <Col className="gutter-row" span={6}>
                                    <StatusCard
                                        style={{ height: "100%" }}
                                        count={formatNumber(dataPartsModule.potencial_ano)}
                                        title={"Potencial Ano"}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col className="gutter-row" span={24}>
                                    <div className="d-flex" style={{ marginBottom: '1rem' }}>
                                        <Search placeholder="Pesquise aqui..." enterButton onSearch={search} />
                                        <ModalStockStrategy style={{ marginLeft: 10 }} textButton="Criar Plano de Ação" />
                                        <Button icon={<DownloadOutlined />} style={{ marginLeft: '1rem' }} onClick={downloadData}>Baixar Lista de Clientes</Button>
                                    </div>
                                    <Table columns={columns} dataSource={dataFiltered} />
                                    <Modal scrollable title={dataDetailsSelected.nome} width={1000} okText="Fechar" open={showModalDetails} onOk={() => { setShowModalDetails(false) }} onCancel={() => { setShowModalDetails(false) }} cancelButtonProps={{ style: { display: 'none' } }}>
                                        <Row gutter={16} style={{ width: '100%', margin: 0, marginBottom: '1rem' }}>
                                            <Col span={8}>
                                                <Card>
                                                    <Statistic title="Potencial Preventivo" value={formatCurrency(dataDetailsSelected.potencialPreventivo)} />
                                                </Card>
                                            </Col>
                                            <Col span={8}>
                                                <Card>
                                                    <Statistic title="Potencial Corretivo" value={formatCurrency(dataDetailsSelected.potencial_corretivo)} />
                                                </Card>
                                            </Col>
                                            <Col span={8}>
                                                <Card>
                                                    <Statistic title="Potencial Total" value={formatCurrency(dataDetailsSelected.potencialTotal)} />
                                                </Card>
                                            </Col>
                                        </Row>
                                        <Table
                                            columns={columnsDetails}
                                            expandable={{ expandedRowRender, defaultExpandedRowKeys: ['0'] }}
                                            dataSource={dataDetailsSelected.machines}
                                            size="small"
                                        />
                                    </Modal>
                                </Col>
                            </Row>
                        </CardGeneral>
                    </Col>
                </Row>

                {/* POTENCIAL VENDAS POR MES */}
                <Row gutter={16}>
                    <Col className="gutter-row" span={24}>

                        <CardGeneral
                            title="Potencial de venda por mês"
                        >
                            <Row gutter={16}>
                                <Col className="gutter-row" span={16}>
                                    <CardGeneral title="Potencial De Venda Por Mês">
                                        <Chart
                                            options={chartBar?.options}
                                            series={chartBar?.series}
                                            type='bar'
                                            height='250px'
                                        />
                                    </CardGeneral>
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    <CardGeneral
                                        title="Participação por Dealer"
                                    >
                                        <Chart
                                            options={chartPie?.options}
                                            series={chartPie?.series}
                                            type='donut'
                                            height='300px'
                                        />
                                    </CardGeneral>
                                </Col>
                            </Row>
                        </CardGeneral>
                    </Col>
                </Row>

                {/* PERFORMANCE MARKET SHARE */}
                <Row gutter={16}>
                    <Col className="gutter-row" span={24}>

                        <CardGeneral
                            title="Performance Market Share"
                        >
                            <Row gutter={16}>
                                <Col className="gutter-row" span={16}>
                                    <CardGeneral title="Performance Market Share" style={{ height: '100%' }}>
                                        <Chart
                                            options={chartBarMarketShareLine?.options}
                                            series={chartBarMarketShareLine?.series}
                                            type='bar'
                                            height='250px'
                                        />
                                    </CardGeneral>
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    <CardGeneral
                                        title="Participação por Segmentos"
                                        style={{ height: '100%' }}
                                    >
                                        <Chart
                                            options={chartPieMarketShare?.options}
                                            series={chartPieMarketShare?.series}
                                            type='donut'
                                            height='300px'
                                        />
                                    </CardGeneral>
                                </Col>
                            </Row>
                            <Row gutter={16} style={{marginTop: 16}}>
                                <Col className="gutter-row" span={24}>
                                    <CardGeneral title="Performance Market Share" style={{ height: '100%' }}>
                                        <Chart
                                            options={chartBarMarketShare?.options}
                                            series={chartBarMarketShare?.series}
                                            type='bar'
                                            height='250px'
                                        />
                                    </CardGeneral>
                                </Col>
                            </Row>
                        </CardGeneral>
                    </Col>
                </Row>

            </Spin>
        </Layout>
    )

};

export default Inicio;
