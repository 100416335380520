export const verifyIfWasChangedUserState = (userInitialState, user) => {
  const objOne = {
    id: userInitialState.id,
    name: userInitialState.name,
    username: userInitialState.username,
  };
  const objTwo = {
    id: user.id || "",
    name: user.name || "",
    username: user.username || "",
  };

  return JSON.stringify(objOne) !== JSON.stringify(objTwo);
};
