import ThemeReducer from "./reducers/ThemeReducer";
import UserReducer from "./reducers/UserReducer";
import AuthReducer from "./reducers/AuthReducer ";
import LoadingReducer from "./reducers/LoadingReducer";
import LogReducer from "./reducers/LogReducer";
import DashboardReducer from "./reducers/DashboardReducer";
import { combineReducers, createStore } from "redux";

const rootReducer = combineReducers({
  ThemeReducer,
  UserReducer,
  AuthReducer,
  LoadingReducer,
  LogReducer,
  DashboardReducer,
});

const store = createStore(rootReducer);

export default store;
