import { List } from 'antd'
import Paragraph from 'antd/lib/typography/Paragraph'
import Title from 'antd/lib/typography/Title'
import './styles.css'

const PreviewResultList = ({ csvContent }) => {

    const resultadosFinais = [
        { id: 'key_accounts', text: 'Base Key Accounts - Maior propensão de conversão' },
        { id: 'mais_propensao', text: 'Base de clientes de alto valor - Boa propensão de conversão​' },
        { id: 'menos_propensao', text: 'Base de clientes com menor propensão - Atendimento digital e/ou Televendas​' },
    ];

    function encode(text) {
        var textIsInvalid = text === undefined ||
            typeof text !== 'string' ||
            text.length < 1;

        if (textIsInvalid) throw new Error('Cannot encodeURIComponent(string),' + ' string is invalid');

        return encodeURIComponent(text);
    }

    return (
        <div>
            <Title level={3}>Base de resultado da ação</Title>
            <List
                size="large"
                header={<Title level={5}>Lista de resultados</Title>}
                bordered
                dataSource={resultadosFinais}
                renderItem={
                    item => {
                        const csv = !!csvContent[item.id] ? csvContent[item.id] : csvContent['csv:']
                        return (
                            <List.Item>
                                <Paragraph>{item.text}</Paragraph>
                                <a
                                    href={'data:attachment/csv,' + encode(csv)}
                                    target="_blank"
                                    download={`${item.id}.csv`}
                                    className="ant-btn ant-btn-primary"
                                >
                                    Baixar CSV
                                </a>
                            </List.Item>
                        )
                    }
                }
            />
        </div>
    )
}

export default PreviewResultList