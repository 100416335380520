import store from "../store";
import axios from "axios";
import LoadingAction from "../store/actions/LoadingAction";
import { toast } from "react-toastify";

export const getSegments = async () => {
  const token = store.getState().AuthReducer.token.access_token;
  return await axios
    .get("https://data-driven.azurewebsites.net/dashboard/segments", {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(res => res.data)
    .catch(e => console.error(e));
};

export const getRegions = async () => {
  const token = store.getState().AuthReducer.token.access_token;
  return await axios
    .get("https://data-driven.azurewebsites.net/dashboard/regions", {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(res => res.data)
    .catch(e => console.error(e));
};

export const getProducts = async () => {
  const token = store.getState().AuthReducer.token.access_token;
  return await axios
    .get("https://data-driven.azurewebsites.net/dashboard/products?tipo=MAQUINAS", {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(res => res.data)
    .catch(e => console.error(e));
};

export const getParts = async () => {
  const token = store.getState().AuthReducer.token.access_token;
  return await axios
    .get("https://data-driven.azurewebsites.net/dashboard/products?tipo=PECAS", {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(res => res.data)
    .catch(e => console.error(e));
};

export const getUserDashboards = async filters => {
  try {
    const token = store.getState().AuthReducer.token.access_token;
  
    console.log(filters);
  
    return await axios
      .get("https://data-driven.azurewebsites.net/dashboard/", {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: filters ? filters : null
      })
      .then(res => res?.data)
      .catch(e => console.error(e));
  } catch (e) {
    console.log(e)
  }
};

export const getUserClients = async () => {
  const token = store.getState().AuthReducer.token.access_token;

  return await axios
    .get("https://data-driven.azurewebsites.net/dashboard/clients", {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(res => res.data)
    .catch(e => console.error(e));
};

export const getDashboard = async id => {
  const token = store.getState().AuthReducer.token.access_token;

  return await axios
    .get(`https://data-driven.azurewebsites.net/dashboard/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(res => {
      console.log(res.data);
      return res.data;
    })
    .catch(e => console.error(e));
};

export const createDashboard = async ({ data }) => {
  store.dispatch(LoadingAction.setLoading(true));

  const token = store.getState().AuthReducer.token.access_token;

  const formData = new FormData();

  formData.append("data", data);

  const config = {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${token}`
    }
  };

  return await axios
    .post(`https://data-driven.azurewebsites.net/dashboard/`, formData, config)
    .then(res => {
      localStorage.setItem("hasDashboard", true);
      return res.data;
    })
    .catch(error => {
      toast.error(error?.response.data.description, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    })
    .finally(() => {
      store.dispatch(LoadingAction.setLoading(false));
    });
};

export const updateDashboard = async (id, xlsFile) => {
  const token = store.getState().AuthReducer.token.access_token;
  store.dispatch(LoadingAction.setLoading(true));

  const formData = new FormData();

  formData.append("data", xlsFile);

  const config = {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${token}`
    }
  };

  return await axios
    .put(`https://data-driven.azurewebsites.net/dashboard/${id}`, formData, config)
    .then(res => {
      store.dispatch(LoadingAction.setLoading(false));
      localStorage.setItem("hasDashboard", true);
      return res.data;
    })
    .catch(e => console.error(e));
};

export const deleteDashboard = async id => {
  const token = store.getState().AuthReducer.token.access_token;
  store.dispatch(LoadingAction.setLoading(true));

  return await axios
    .delete(`https://data-driven.azurewebsites.net/dashboard/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(res => {
      store.dispatch(LoadingAction.setLoading(false));
      localStorage.setItem("hasDashboard", false);
      return res.data;
    })
    .catch(e => console.error(e));
};

const dashboardService = {
  getDashboard,
  getUserDashboards,
  createDashboard,
  updateDashboard,
  deleteDashboard,
  getSegments
};

export default dashboardService;
