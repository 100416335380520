const inistialState = {}

const AuthReducer = (state = inistialState, action) => {
    switch(action.type) {
        case 'SET_TOKEN':
            return {
                ...state,
                token: action.payload
            }
        default:
            return state
    }
}

export default AuthReducer