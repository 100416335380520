import React from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { deleteDashboard } from "../../services/dashboard.service";
import DashboardAction from "../../store/actions/DashboardAction";
import { checkDashboard } from "../../utils/check-dashboard";
import Button from "../Button";

import './styles.css'

const DeleteButton = ({ onDeleteButtonOpen, onDeleteButtonClose, modalState, dashboardId }) => {
    const dispatch = useDispatch()
    const history = useHistory()

    const handleOpenDeleteButton = () => {
        onDeleteButtonOpen()
    }

    const handleCloseDeleteButton = () => {
        onDeleteButtonClose()
    }

    const handleConfirm = () => {
        deleteDashboard(dashboardId)
            .catch((error) => {console.error(error, error.response)})
            .finally(() => {
            localStorage.removeItem('dashboard')
            dispatch(DashboardAction.setDashboard({}))
            // checkDashboard(history)
            onDeleteButtonClose()
            document.location.reload(true);
        })
    }

    return (
        <>
            {/* <button className="delete-button button" onClick={handleOpenDeleteButton}>
                <i className="bx bx-trash"></i> Excluir Dashboard
            </button> */}
            
            <Button border={true} onClick={handleOpenDeleteButton}>
               <i className="bx bx-trash"></i> Excluir Dashboard
            </Button>

            <Modal
                isOpen={modalState}
                onRequestClose={handleCloseDeleteButton}
                overlayClassName={'react-modal-overlay'}
                className={'react-modal-content'}
            >
                <h2>Deseja mesmo excluir esta planilha?</h2>
                <div className="step">
                    <p>Essa é uma ação irreversível, deseja continuar?</p>
                </div>

                <div className="confirm-button__container">
                    <button className="button" onClick={handleCloseDeleteButton}>
                        <i className="bx bx-left-arrow-alt"></i>
                        Cancelar
                    </button>
                    <button className="delete-button" onClick={handleConfirm}>
                        <i className="bx bx-trash"></i>
                        Excluir
                    </button>
                </div>

            </Modal>
        </>
    );
}

export default DeleteButton