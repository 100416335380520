import React from 'react'
import HeroSVG from '../HeroSVG'
import './styles.css'

export default function Hero({ title, message, svgClassName, paragColor, children, childrenClassName, center }) {
  return (
    <div className={center ? "hero__container container margin-auto-content" : "hero__container container"}>
      <h1>{title}</h1>
      <p style={{color: '#230B5C'}} >{message}</p>
      {children && <div className={childrenClassName}>
      {children}
      </div>}
      <div className={svgClassName || ''}>
        <HeroSVG />
      </div>
    </div>
  )
}
