export const setToken = token => {
    return {
        type: 'SET_TOKEN',
        payload: token
    }
}

const getToken = () => {
    return {
        type: 'GET_TOKEN'
    }
}

const AuthAction = {setToken, getToken}

export default AuthAction
