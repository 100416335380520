import React, { useEffect, useState } from 'react'
import { getNotifications } from '../../services/notification.service'
import LoadingAction from "../../store/actions/LoadingAction";
import Layout from '../../components/Layout'
import store from "../../store";

import './styles.css'
import NotificationsBoard from '../../components/NotificationsBoard'


const Notifications = (props) => {

    const [notifications, setNotifications] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            store.dispatch(LoadingAction.setLoading(true));
            const data = await getNotifications().finally(() => { store.dispatch(LoadingAction.setLoading(false)); });
            console.log(data)
            setNotifications(data.notifications);
            setLoading(false);
        }
        fetchData();
    }, [])
    

    return (
        <Layout>
            <h2 style={{ marginBottom: '1rem' }}>
                Insights do Guru
            </h2>
            {!loading && <NotificationsBoard notifications={notifications} />}
        </Layout>
    )
}

export default Notifications
