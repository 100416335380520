import { useEffect, useState } from 'react'
import { formatCurrency, formatNumber, formatPercent } from '../../utils/format'
import CardGeneral from '../CardGeneral'
import StatusCard from '../StatusCard'
import explanationTotalizadores from '../../services/JsonData/explanationTotalizadores.json'
import './styles.css'

const TotalizadosDashboard = ({ setIsModalVisible, data }) => {

    const [statusCards, setStatusCards] = useState([]);

    const generateObject = (icon, count, title, action, textMoreInfo ) => { 
        return { icon, count, title, action, textMoreInfo }
    }

    function generateData(data) {
        return [
            generateObject(
                "",
                formatNumber(data.clientes_unicos),
                "Quantidade de clientes únicos",
                () => setIsModalVisible(true), 
                explanationTotalizadores.quantidadeClientesUnicos
            ),
            generateObject(
                "",
                formatNumber(data.total_vendas),
                "Número absoluto de vendas",
                () => {},
                explanationTotalizadores.numeroAbsolutoVendas
            ),
            generateObject(
                "",
                formatNumber(data.tempo_recompra),
                "Tempo médio de recompra em meses",
                () => {},
                explanationTotalizadores.tempoMedioRecompraMeses
            ),
            generateObject(
                "",
                formatCurrency(data.faturamento_total),
                "Valor total de vendas",
                () => {},
                explanationTotalizadores.valorTotalVendas
            ),
            generateObject(
                "",
                formatCurrency(data.ticket_medio),
                "Ticket médio",
                () => {},
                explanationTotalizadores.ticketMedio
            ),
            generateObject(
                "",
                formatNumber(data.frequencia_media),
                "Frequência de compras por clientes",
                () => {},
                explanationTotalizadores.frequenciaComprasCliente
            ),
            generateObject(
                "",
                formatNumber(data.novos_clientes),
                "Novos clientes",
                () => {},
                explanationTotalizadores.novosClientes
            ),
            generateObject(
                "",
                formatPercent(data.crescimento_clientes),
                "Crescimento da base de clientes",
                () => { },
                explanationTotalizadores.crescimentoBaseClientes
            )
        ]
    }
    
    useEffect(() => {
        data && setStatusCards(generateData(data))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    return (
        <CardGeneral title="Totalizadores">                    
            <div className="row stats">
                {
                    statusCards.map((item, index) => (
                        <div className="col-3" key={index}>
                            <StatusCard
                                icon={item.icon}
                                count={item.count}
                                title={item.title}
                                hasMoreInfo={true}
                                action={item.action}
                                textMoreInfo={item.textMoreInfo}
                            />
                        </div>
                    ))
                }
            </div>
        </CardGeneral>
    )
}

export default TotalizadosDashboard