import { Card } from 'antd'
import Title from 'antd/lib/typography/Title'
import { formatCurrency, formatNumber, formatPercent } from '../../utils/format'
import PreviewResultList from '../PreviewResultList'
import './styles.css'

const StockStrategyView = ({ data }) => {

    return (
        <>
            {data?.data?.quartil && <div><b>Quartil:</b> {data?.data?.quartil}</div>}
            <div><b>Segmento:</b> {data?.data?.segmento}</div>
            <div><b>Região:</b> {data?.data?.regiao}</div>
            <br />
            <div className="content-card-step-2" style={{ paddingLeft: 0, paddingRight: 0 }}>
                <Card bordered style={{ flex: 1 }}>
                    <Title level={5} type="secondary">Pontencial Capturável</Title>
                    <Title level={3}>{formatCurrency(data?.data?.potencial_capturavel)}</Title>
                </Card>
                <Card bordered style={{ flex: 1 }}>
                    <Title level={5} type="secondary">Quantidade de clientes impactados</Title>
                    <Title level={3}>{formatNumber(data?.data?.clientes_afetados_num)}</Title>
                </Card>
                <Card bordered style={{ flex: 1 }}>
                    <Title level={5} type="secondary">Meta de conversão de vendas</Title>
                    <Title level={3}>{formatPercent(Number(data?.data?.salesConversionGoal) / 100)}</Title>
                    <Title level={5}>
                        ({formatCurrency(data?.data?.potencial_capturavel * Number(data?.data?.salesConversionGoal) / 100)})
                    </Title>
                </Card>
            </div>
            <br />
            <PreviewResultList csvContent={data?.data} />
        </>
    )
}

export default StockStrategyView
