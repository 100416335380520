import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
// import CDropdown from '../Dropdown'
// import user_menu from '../../services/JsonData/user_menus.json'
import { signOutAuth } from '../../auth'
import { useSelector } from 'react-redux'
import { getNotifications } from '../../services/notification.service'
import { Menu, Dropdown, Space, Badge, Divider, message } from 'antd';
import { DownOutlined, UserOutlined, LogoutOutlined, BellOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";
 
import './styles.css'

// const renderNotificationItem = (item, index) => (
//     <div className="notification-item" key={index}>
//         <i className={item.icon}></i>
//         <span>{item.content}</span>
//     </div>
// )

const renderUserToggle = (user) => (
    <div className="topnav__right-user">
        {/* <div className="topnav__right-user__name">
            <h2 className="name">{user?.name}</h2>
            <h2 className="position">{user?.username}</h2>
        </div> */}
        {user?.imageUrl ? <div className="topnav__right-user__image">
            <img src={user.image} alt="" />
        </div> : 
        <div className='topnav__right-user__initial'>
            <strong className="user-initial">
                <UserOutlined style={{fontSize: '1.35rem'}} />
                {/* {user.name?.split(" ").map((n) => n[0]).join(".")} */}
            </strong>
        </div>
        }
    </div>
)

// const renderUserMenu = (item, index) => (
//     <Link to='/' key={index} onClick={item.content.toLowerCase() === 'sair' ? signOutAuth : null} >
//         <div className="notification-item">
//             <i className={item.icon}></i>
//             <span >{item.content}</span>
//         </div>
//     </Link>
// )

const generateDropdownNotificationsMenu = (data) => {
    return (
        <Menu mode="horizontal">
            {
                data && data.map(
                    ({ title }) => {
                        return (
                            <Menu.Item style={{paddingTop: '1rem', paddingBottom: '1rem' }} key={title} >
                                <Space size={8}>
                                    <ExclamationCircleOutlined /> {title}
                                </Space>
                            </Menu.Item>
                        )
                    }
                )
            }
            <Menu.Item style={{paddingBottom: '1rem' }} key="view-all" >
                <div style={{ width: '100%', textAlign: 'center' }}>
                    <Divider style={{marginTop: '0', marginBottom: '0.75rem'}} />
                    <Link style={{color: '#CE1459'}} to='/notifications'>Visualizar Todos</Link>
                </div>
            </Menu.Item>
        </Menu>
    )
};

const Topnav = () => {
    const userReducer = useSelector(state => state.UserReducer)
    const history = useHistory()

    const onClick = ({ key }) => {
        const funcoes = {
            profile: () =>  message.info(`Página de perfil está em construção`),
            logout: () => signOutAuth(history)
        }
        funcoes[key]()
    };

    const menuUser = (
        <Menu mode="horizontal" onClick={onClick}>
            <Menu.Item style={{paddingTop: '1rem', paddingBottom: '1rem' }} key="profile" >
                <Space size={16}>
                    <UserOutlined /> Perfil
                </Space>
            </Menu.Item>
            <Menu.Item style={{paddingTop: '1rem', paddingBottom: '1rem' }} key="logout" >
                <Space size={16}>
                    <LogoutOutlined /> Sair
                </Space>
            </Menu.Item>
        </Menu>
    )

    const [countNotificationUnread, setCountNotificationUnread] = useState('')
    const [notifications, setNotifications] = useState([])

    useEffect(() => {
        async function fetchData() {
            const data = await getNotifications(3);
            setCountNotificationUnread(data?.nUnreadNotifs)
            setNotifications(generateDropdownNotificationsMenu(data?.notifications))
        }
        fetchData()
    }, [])

    return (
        <div className='topnav'>
            <div className="topnav__search">
                {/* <i className='bx bx-search'></i>
                <input type="text" placeholder='Search...' /> */}
            </div>
            <div className="topnav__right">
                <Space size={8} split={<Divider type="vertical" />} >
                    <div className="topnav__right-item" style={{ display: 'flex' }}>
                        {/* NOTIFICAÇÃO */}
                        {/* <CDropdown
                            icon='bx bx-bell'
                            badge={countNotificationUnread > 0 ? countNotificationUnread : ''}
                            contentData={notifications}
                            renderItems={(item, index) => renderNotificationItem(item, index)}
                            renderFooter={() => <Link to='/notifications'>Visualizar Todos</Link>}
                        /> */}
                        <Dropdown overlay={notifications} placement="bottomRight" arrow>
                            <Badge count={countNotificationUnread > 0 ? countNotificationUnread : ''}>
                                <BellOutlined style={{ fontSize: '1.75rem' }} />
                            </Badge>
                        </Dropdown>
                    </div>
                    <div className="topnav__right-item">
                        {/* USER OPTIONS */}
                        <Dropdown overlay={menuUser} placement="bottomRight" arrow>
                            {renderUserToggle(userReducer)}
                        </Dropdown>
                        {/* <CDropdown
                            customToggle={() => renderUserToggle(userReducer)}
                            contentData={user_menu}
                            renderItems={(item, index) => renderUserMenu(item, index)}
                        /> */}
                    </div>
                </Space>
                {/* <div className="topnav__right-item">
                    <ThemeMenu />
                </div> */}
            </div>
        </div>
    )
}

export default Topnav
