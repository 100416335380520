import store from "../store";
import axios from "axios";

const getToken = () => store.getState()?.AuthReducer?.token?.access_token
const path = 'action_plan/'
const baseURL = `https://data-driven.azurewebsites.net/${path}`;
const headers = () => ({ headers: { Authorization: `Bearer ${getToken()}` }})

// GET
export const getActionPlans = async () => {
  return await axios.get(baseURL, headers())
    .then(res => res.data)
    .catch(e => console.error(e));
};

export const getActionPlanById = async (id) => {
  return await axios.get(`${baseURL}${id}`, headers())
    .then(res => res.data)
    .catch(e => console.error(e));
};

export const getProducts = async (tipo) => {
  return await axios.get(
    `https://data-driven.azurewebsites.net/dashboard/products?tipo=${tipo}`,
    headers()
  )
    .then(res => res.data)
    .catch(e => console.error(e));
};


// POST
export const getPreviewActionPlans = async (actionPlan) => {
  return await axios.post(`${baseURL}preview`, {...actionPlan }, headers())
    .then(res => res.data)
    .catch(e => console.error(e));
};

export const createActionPlan = async (actionPlan) => {
  return await axios.post(baseURL, {...actionPlan}, headers())
    .then(res => res.data)
    .catch(e => console.error(e));
};

// PUT
export const updateActionPlan = async (actionPlan) => {
  return await axios.put(baseURL + actionPlan.id, {...actionPlan}, headers())
    .then(res => res.data)
    .catch(e => console.error(e));
};

//DELETE 
export const removeActionPlan = async (id) => {
  return await axios.delete(`${baseURL}${id}`, headers())
    .then(res => res.data)
    .catch(e => console.error(e));
};