import React from 'react';
import Hero from '../../components/Hero';
import Navbar from '../../components/Navbar';
import SignForm from '../../components/SignForm';

import './styles.css'

const SignIn = () => {
  const title = "Não sabe por onde começar a escalar seu negócio?"
  const message = "Que tal se através do seus dados nós pudéssemos te ajudar?"
  return <div className="sign-in__container container">
    <Navbar />
    <div className="sign-in__content">
      <Hero title={title} message={message} svgClassName={'signin-svg'}/>
      <SignForm />
    </div>
  </div>
}

export default SignIn;